.font-face-ar {
  font-family: 'Almarai', Helvetica, sans-serif;
}

.font-face-ar {
  font-family: 'Almarai', Helvetica, sans-serif !important;
}

.font-face-en {
  font-family: 'Inter', Helvetica, sans-serif !important;
}

body {
  font-family: 'Inter', Helvetica, sans-serif !important;
  font-size: 1.3rem;
  line-height: 1.875em;
  color: #5a6671;
  font-weight: 500;
}

.heroBg {
  /*background-color: #4d6cfa !important;*/
  background-position: top right;
  background-size: 50%;
}

.heroImage {
  width: 86%;
  height: 100%;
}

@media (max-width: 575.98px) {
  .heroImage {
    width: 100%;
    height: 100%;
  }
}

.RadiusHeader {
  border-bottom-width: 1px;
  border-color: rgb(250, 250, 250);
  border-left-width: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-top-width: 1px;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 20px 0px;
  opacity: 1;
}

.hero {
  flex: none;
  inset: 0;
  mask: radial-gradient(circle, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
  mask-composite: exclude;
  opacity: 0.07;
  position: absolute;
  z-index: 1;
}

.heroSection {
  position: absolute;
  border-radius: inherit;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: repeat;
  background-position: center;
  background-size: 38.4px auto;
  background-image: url(https://framerusercontent.com/images/wGAHOWhVswEtWkOKTJN6s2CW0.svg);
  border: 0;
}
.topSection {
  padding-top: 145px !important;
}

.maxWidth {
  max-width: 100%;
}

@supports ((position: fixed)) {
  .sticky-top {
    height: auto;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 18;
    padding-top: 10px;
    background: linear-gradient(
      rgb(255, 255, 255) 30%,
      rgba(255, 255, 255, 0) 70%
    );
  }
}

.bar .progress {
  background: inherit;
}

.phoneInput img {
  vertical-align: top;
}

.modal-image {
  width: 80%;
  height: 80%;
  border-radius: 25px;
  border: 1px solid #dfdfdf;
  padding: 5px;
  background-color: #ffffff;
}

.invetory-modal-image {
  width: 60%;
  height: 70%;
  background-color: transparent;
}

@media (max-width: 575.98px) {
  .modal-image {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 575.98px) {
  .normal-image {
    width: 100%;
    height: 100%;
  }
}

.small-image {
  width: min-content !important;
}

.client-image {
  width: 80%;
  height: 80%;
}

@media (max-width: 575.98px) {
  .client-image {
    width: 100%;
    height: 100%;
  }
}

.label-image {
  max-width: 135px;
  max-height: 40px;
}

.zakatLogo {
  max-width: 235px;
  max-height: 90px;
}

@media (max-width: 575.98px) {
  .label-image {
    max-width: 120px;
    max-height: 80px;
    padding-top: 20px;
    align-items: left;
    margin-left: 10px;
  }

  .labels_row {
    padding-left: 5px !important;
    margin-left: 5px !important;
  }
}

.phoneInput .react-phone-number-input__icon {
  border: none;
}

@media (max-width: 575.98px) {
  .main-container {
    padding-top: 200px;
  }
}

.shape_1 {
  position: absolute;
  width: 260px;
  left: -80px;
  bottom: -140px;
  z-index: -2;
}

@media (max-width: 575.98px) {
  .shape_1 {
    left: -70px;
    bottom: 10px;
  }
}

.shape_2 {
  position: absolute;
  width: 260px;
  left: -20px;
  top: -50px;
  z-index: -2;
}

@media (max-width: 575.98px) {
  .shape_2 {
    left: -70px;
    top: -180px;
  }
}

*:focus {
  outline: none;
}

img {
  max-width: 100%;
}

.footer-logo {
  width: 163px;
  height: 56px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #000000;
  line-height: 1.37em;
}

h1,
.h1 {
  font-weight: bold;
  font-size: 40px;
}
@media (max-width: 767px) {
  h1,
  .h1 {
    font-size: 30px;
  }
}

h2,
.h2 {
  font-size: 30px;
}
@media (max-width: 767px) {
  h2,
  .h2 {
    font-size: 28px;
  }
}

h3,
.h3 {
  font-size: 26px;
}

h4,
.h4 {
  font-size: 24px;
}

h5,
.h5 {
  font-size: 22px;
}

h5,
.h5 {
  font-size: 20px;
}

a {
  color: inherit;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  color: inherit;
  text-decoration: none;
}

/*button,
input,
optgroup,
select,
textarea {
  font-family: 'Almarai', sans-serif;
}
*/
b,
strong {
  font-weight: 600;
  color: #273f59;
}

sup {
  top: -1.3em;
}

sub {
  bottom: -1.3em;
}

section {
  padding-top: 150px;
  padding-bottom: 50px;
  position: relative;
  z-index: 10;
}
@media (max-width: 991px) {
  section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

input,
textarea {
  border: 1px solid #e9eff5;
  padding: 9px 30px;
  border-radius: 4px;
  width: 100%;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #8ea4b8;
  font-size: 1rem;
  opacity: 1;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #8ea4b8;
  font-size: 1rem;
  opacity: 1;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #8ea4b8;
  font-size: 1rem;
  opacity: 1;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #8ea4b8;
  font-size: 1rem;
  opacity: 1;
}

@media (max-width: 767px) {
  .display-1 {
    font-size: 4rem;
  }
  .display-2 {
    font-size: 3.5rem;
  }
  .display-3 {
    font-size: 3rem;
  }
  .display-4 {
    font-size: 2.5rem;
  }
}

.show-onHover {
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.show-onHover-parent:hover .show-onHover {
  opacity: 1;
}

[data-dark-overlay] {
  position: relative;
}
[data-dark-overlay]:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  z-index: -1;
}

[data-dark-overlay='1']:after {
  opacity: 0.1;
}

[data-dark-overlay='2']:after {
  opacity: 0.2;
}

[data-dark-overlay='3']:after {
  opacity: 0.3;
}

[data-dark-overlay='4']:after {
  opacity: 0.4;
}

[data-dark-overlay='5']:after {
  opacity: 0.5;
}

[data-dark-overlay='6']:after {
  opacity: 0.6;
}

[data-dark-overlay='7']:after {
  opacity: 0.7;
}

[data-dark-overlay='8']:after {
  opacity: 0.8;
}

[data-parallax] {
  background-color: transparent;
}

.typed-curcor {
  -webkit-animation-name: blinker;
  animation-name: blinker;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

@-webkit-keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.hamburger {
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  -o-transition-property: opacity, filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 1px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
}

.is-scrolling .hamburger-inner,
.is-scrolling .hamburger-inner::before,
.is-scrolling .hamburger-inner::after {
  background-color: #54667a;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block;
}

.hamburger-inner::before {
  top: -9px;
}

.hamburger-inner::after {
  bottom: -9px;
}

.hamburger--spin .hamburger-inner {
  -webkit-transition-duration: 0.22s;
  -o-transition-duration: 0.22s;
  transition-duration: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  -o-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  -webkit-transition: bottom 0.1s 0.25s ease-in,
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  -webkit-transition-delay: 0.12s;
  -o-transition-delay: 0.12s;
  transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
  -o-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: bottom 0.1s ease-out,
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r .hamburger-inner {
  -webkit-transition-duration: 0.22s;
  -o-transition-duration: 0.22s;
  transition-duration: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  -o-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin-r .hamburger-inner::after {
  -webkit-transition: bottom 0.1s 0.25s ease-in,
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-225deg);
  -ms-transform: rotate(-225deg);
  transform: rotate(-225deg);
  -webkit-transition-delay: 0.12s;
  -o-transition-delay: 0.12s;
  transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
  -o-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: bottom 0.1s ease-out,
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.header {
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.navbar-brand {
  position: relative;
}

.navbar-nav > .nav-item {
  /*  margin-left: 15px;*/
}
.navbar-nav > .nav-item > .nav-link {
  font-weight: 600;
  font-size: 18px;
}
.navbar-nav > .nav-item > .nav-link.active {
  color: #3564ff;
}

.navbar-toggler {
  color: #fff;
  position: relative;
}
.navbar-toggler:focus {
  outline: none;
}
.navbar-toggler * {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.navbar-toggler[aria-expanded='true'] .nav-hamburger {
  opacity: 0;
}
.navbar-toggler[aria-expanded='true'] .nav-close {
  opacity: 1;
}
.navbar-toggler[aria-expanded='false'] .nav-hamburger {
  opacity: 1;
}
.navbar-toggler .nav-close {
  position: absolute;
  opacity: 0;
  top: 3px;
}

.dropdown-toggle::after {
  display: none;
}

/*.dropdown-menu,
.dropdown__col-2 {
  border: none !important;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 15px;
  min-width: 13rem;
}*/

.dropdown-item {
  border-radius: 4px;
  padding: 5px 15px;
  text-transform: capitalize;
}
.dropdown-item.active,
.dropdown-item:hover,
.dropdown-item:active {
  background: #3564ff;
  color: #fff;
}

@media (min-width: 992px) {
  .dropdown-menu {
    border: none;
    margin-top: 20px;
  }
  .dropdown-menu.show {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }
  .dropdown:hover > .dropdown-menu,
  .dropdown:hover .dropdown__col-2 {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
    z-index: 1030;
  }
}

@media (min-width: 992px) {
  .dropdown__col-2 {
    min-width: 450px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .dropdown__col-2 .dropdown-item {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    font-size: 15px;
  }
}

.header-inverse {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.header-inverse .nav-link {
  color: #fff;
}
.header-inverse-false {
  background: #fff;
}

.is-scrolling .fixed-top {
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.is-scrolling .navbar-toggler {
  color: #54667a;
}

.is-scrolling .header-inverse {
  background: #fff !important;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.is-scrolling .header-inverse .nav-link {
  color: #54667a;
}

.is-scrolling .header-shrink {
  padding-top: 0px;
  padding-bottom: 0px;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
}

.icon_spacing {
  letter-spacing: 0.1rem;
}

.header-shadow {
  -webkit-box-shadow: 0 3px 20px 0 rgba(0, 129, 255, 0.1);
  box-shadow: 0 3px 20px 0 rgba(0, 129, 255, 0.1);
}

@media (max-width: 991px) {
  #navbarNav {
    background: #fff !important;
    max-height: 100vh;
    padding: 20px;
    overflow: auto;
  }
  #navbarNav .nav-link {
    color: #000;
  }
  #navbarNav .navbar-nav > .nav-item > .nav-link.active {
    color: #3564ff !important;
  }
}

.intro-hero {
  padding-top: 150px;
  padding-bottom: 150px;
  background-size: cover !important;
  position: relative;
  z-index: 10;
}

.social a {
  width: 45px;
  height: 45px;
  background: rgba(255, 255, 255, 0.1);
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  font-size: 18px;
  line-height: 45px;
}
.social a:hover {
  background: #339aff;
}

.social.social-white a {
  background: #fff;
  color: #92b7db;
}
.social.social-white a:hover {
  color: #3564ff;
}

.social-rounded a {
  border-radius: 50%;
}

.social-default a {
  background: #ecf5fe;
  color: #92b7db;
}
.social-default a:hover {
  color: #3564ff;
  background: #ecf5fe;
}

.social-sm a {
  width: 35px;
  height: 35px;
  font-size: 16px;
  line-height: 35px;
}

.scroll-top {
  bottom: 120px;
  font-size: 20px;
  height: 40px;
  position: fixed;
  text-align: center;
  width: 40px;
  z-index: 10;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50%;
  line-height: 40px;
  right: -100px;
}
.scroll-top.active {
  right: 41px;
}

.btn {
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px !important;
  padding: 0.8125rem 2rem;
}
.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-rounded {
  border-radius: 100em;
}

.btn-primary {
  background: #3564ff;
  border-color: #3564ff;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}
.btn-primary:hover {
  background: #6f80fd;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #6f80fd;
}

.btn-white {
  background: #fff;
  -webkit-box-shadow: 0 5px 40px 0 rgba(32, 101, 169, 0.2);
  box-shadow: 0 5px 40px 0 rgba(32, 101, 169, 0.2);
  color: #597187 !important;
}
.btn-white:hover {
  background: #455768;
  color: #fff !important;
}

.btn-outline-white {
  background: rgba(44, 212, 74, 0.8);
  border-color: rgba(44, 212, 74, 0.8);
  color: #fff !important;
  text-transform: none;
  letter-spacing: 0.7px;
}

.btn-outline-white:hover {
  background: rgba(44, 212, 74, 0.8);
  border-color: rgba(44, 212, 74, 0.8);
  color: #222222 !important;
  font-weight: 900;
}

.btn-pulple-light {
  background: #a282e3;
  color: #fff;
  -webkit-box-shadow: 0 5px 40px 0 rgba(162, 130, 227, 0.4);
  box-shadow: 0 5px 40px 0 rgba(162, 130, 227, 0.4);
}
.btn-pulple-light:hover {
  background: #8358da;
  color: #fff;
}

.btn-outline-pulple-light {
  background: transparent;
  border-color: #a282e3;
}
.btn-outline-pulple-light:hover {
  background: #a282e3;
  color: #fff;
}

.btn-yellow {
  background: #fcc851;
  -webkit-box-shadow: 0 5px 40px 0 rgba(255, 173, 0, 0.3);
  box-shadow: 0 5px 40px 0 rgba(255, 173, 0, 0.3);
  color: #fff !important;
}
.btn-yellow:hover {
  background: #fcc851;
  color: #fff;
}

.btn-blue {
  background: #33bbff;
  -webkit-box-shadow: 0 5px 40px 0 rgba(106, 205, 255, 0.3);
  box-shadow: 0 5px 40px 0 rgba(106, 205, 255, 0.3);
  color: #fff !important;
}
.btn-blue:hover {
  background: #6acdff;
  color: #fff;
}

.btn-outline-yellow {
  background: transparent;
  border-color: #fcc851;
}
.btn-outline-yellow:hover {
  background: #fcc851;
  color: #fff;
}

.btn-green {
  background: #22891f;
  -webkit-box-shadow: 0 5px 40px 0 rgba(44, 212, 74, 0.3);
  box-shadow: 0 5px 40px 0 rgba(44, 212, 74, 0.3);
  color: #fff !important;
}
.btn-green:hover {
  background: #27bf42;
  color: #fff;
}

.btn-red {
  background: #f64d62;
  -webkit-box-shadow: 0 5px 40px 0 rgba(246, 77, 98, 0.3);
  box-shadow: 0 5px 40px 0 rgba(246, 77, 98, 0.3);
  color: #fff !important;
}
.btn-red:hover {
  background: #f5354d;
  color: #fff;
}

.btn-outline-green {
  background: transparent;
  border-color: #2cd44a;
}
.btn-outline-green:hover {
  background: #27bf42;
  color: #fff;
}

.btn-sm {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px !important;
}

.subsribe-rounded .form-control {
  border-color: #dde9f5;
}
@media (min-width: 576px) {
  .subsribe-rounded .form-control {
    min-width: 460px;
  }
}

@media (min-width: 768px) {
  .subsribe-rounded .btn {
    margin-left: -50px;
  }
}

.accordion__faq {
  border-bottom: 1px solid #eaecef;
}

.accordion__title {
  font-size: 20px;
  color: #273f59;
  font-weight: 600;
  display: block;
}
.accordion__title > i {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.accordion__title.active > i {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion__text {
  padding-left: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
  flex-grow: 1;
}

.tabs-v1 .bg-white {
  color: #476483;
}
.tabs-v1 .bg-white.active {
  color: #273f59;
}

.tabs-v2 {
  background: #fff;
  border-radius: 100em;
  border-bottom: none;
}
@media (max-width: 767px) {
  .tabs-v2 {
    display: block !important;
    border-radius: 0 !important;
  }
}
.tabs-v2 > .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.tabs-v2 .nav-link {
  border-radius: 100em;
  text-align: center;
  padding: 10px;
}
.tabs-v2 .nav-link.active {
  background: #3564ff;
  color: #fff;
}

.tabs-v3 .nav-link {
  background: #ecf5fe;
}
@media (min-width: 600px) {
  .tabs-v3 .nav-link {
    width: 200px;
  }
}
.tabs-v3 .nav-link.active {
  background: #3564ff;
  border-color: transparent;
  color: #fff;
}

.portfolio-block {
  position: relative;
  margin-top: 30px;
}
.portfolio-block:hover .portfolio-overlay {
  opacity: 1;
}
.portfolio-block:hover .portfolio-desc {
  margin-top: 30px;
  opacity: 1;
}

.portfolio-overlay {
  background: #3564ff;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  padding: 20px;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.portfolio-btn-action > a {
  display: inline-block;
  width: 50px;
  height: 50px;
  font-size: 20px;
  border: 1px solid rgba(244, 244, 244, 0.5);
  border-radius: 50%;
  padding: 11px;
  text-align: center;
  margin: 5px;
}
.portfolio-btn-action > a:hover,
.portfolio-btn-action > a:focus {
  color: #fff;
  background: rgba(244, 244, 244, 0.5);
  outline: none;
}

.portfolio-desc {
  margin-top: 100px;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.portfolio-details-text > h2 {
  font-size: 30px;
  margin-bottom: 30px;
  font-weight: normal;
  color: #000;
}

.portfolio-meta {
  padding: 30px;
}
.portfolio-meta ul li {
  padding: 5px;
}
.portfolio-meta ul li strong {
  margin-right: 5px;
  color: #000;
}
.portfolio-meta ul li > a {
  display: inline-block;
  margin: 0 5px;
}

.portfolio-filter {
  border-radius: 50em;
  overflow: hidden;
}
.portfolio-filter > li {
  padding: 10px 30px;
  border-radius: 50em;
  cursor: pointer;
}
.portfolio-filter > li.active {
  color: #fff;
  background: #3564ff;
}

.post {
  margin-bottom: 40px;
}

.post__content {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}
@media (max-width: 767px) {
  .post__content {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
  }
}

.post__date {
  background: #fff;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.post__share {
  float: right;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
}

.post__share-media {
  position: absolute;
  right: 100%;
  top: 0;
  min-width: 200px;
  text-align: right;
}
.post__share-media > a {
  display: inline-block;
}

.share__icon {
  width: 45px;
  height: 45px;
  display: inline-block;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
}

.post-share-and-taq {
  /*  padding-top: 20px;*/
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.post-taq > a {
  padding: 5px;
  display: inline-block;
}

.sidebar-widget,
.widget,
.widget_recent_entries,
.widget_calendar,
.widget_archive,
.widget_recent_entries,
.widget_recent_comments,
.widget_categories,
.widget_text,
.widget_pages,
.widget_meta,
.widget_recent_entries,
.widget_rss {
  -webkit-box-shadow: 0 0 50px 0 rgba(22, 104, 183, 0.15);
  box-shadow: 0 0 50px 0 rgba(22, 104, 183, 0.15);
  background: #fff;
  margin-bottom: 40px;
  border-radius: 4px;
  padding: 30px;
}

.side-post-block {
  padding-bottom: 20px;
}

.side-post-img {
  float: left;
  margin-right: 30px;
}
.side-post-img img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.side-post-content > h4 {
  font-size: 20px;
}

.side-post-content .post-date {
  font-size: 14px;
}

.post__tab-content {
  display: none;
}
.post__tab-content.active {
  display: block;
}

.post-tab-list > h2 {
  width: 50%;
  float: left;
  text-align: center;
}
.post-tab-list > h2 a.active {
  color: #7b8697;
}

.sidebar-widget-title,
.widget > h2,
.widget-title {
  font-size: 24px;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.tags-list > a {
  display: inline-block;
  color: #777;
  text-transform: capitalize;
  padding: 3px 15px;
  background: #f8f8f8;
  margin-right: 5px;
  border-radius: 50px;
  margin-bottom: 10px;
}
.tags-list > a:hover {
  background: #3564ff;
  color: #fff;
}

/***************************WordPress Default Widget style
/***************************/
.widget ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.widget ul > li {
  margin-bottom: 5px;
}
.widget ul > li > a .badge {
  background: #cce6ff;
  color: #6685a4;
  border-radius: 10em;
  padding: 5px 12px;
  font-size: 13px;
  font-weight: 600;
  margin-left: 15px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.widget ul > li > a:hover {
  color: #3564ff;
}
.widget ul > li > a:hover .badge {
  background: #3564ff;
  color: #fff;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.ec-comments-area .comments-title {
  font-size: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
}

.ec-comments-area .comment-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
}

.ec-comments-area .comment-main-area {
  margin-left: 90px;
}

.ec-comments-area .comment {
  padding-bottom: 30px;
}

.ec-comments-area .ec-comments-meta > h4 {
  font-size: 16px;
}

.ec-comments-area .comments-date {
  color: #9c9c9c;
  margin-bottom: 15px;
  display: inline-block;
}

.ec-comments-area .comments .children {
  margin-left: 20px;
  border-top: 1px solid #eee;
  padding-top: 50px;
  padding-bottom: 30px;
  list-style: none;
}

.ec-comments-area .comments-reply {
  float: right;
}

.ec-comments-area .ec-comment-form {
  margin-top: 30px;
}

.ec-comment-form .comment-reply-title {
  font-size: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
}

@media (max-width: 991px) {
  .share-post > p > span {
    display: block;
    margin-bottom: 10px;
  }
}

.progress-bar {
  width: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.u-as-parent {
  width: 100% !important;
  height: 100% !important;
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  left: 0;
  z-index: -1;
}

.u-ff-open-sans {
  font-family: 'Open Sans', sans-serif;
}

.u-of-hidden {
  overflow: hidden;
}

.u-bg-img {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}
.u-bg-img-repeat-x {
  background-repeat: repeat-x !important;
}
.u-bg-img-repeat-y {
  background-repeat: repeat-y !important;
}

.u-bg-parallax,
.u-bg-fixed {
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.u-bg-left-center {
  background-position: left center !important;
}

.u-bg-top-center {
  background-position: top center !important;
}

.u-bg-bottom-center {
  background-position: bottom center !important;
}

.u-bg-transparent {
  background: transparent !important;
}

.u-bdr-x {
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
}
.u-bdr-x-0 {
  border-left: none !important;
  border-right: none !important;
}

.u-bdr-y {
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}
.u-bdr-y-0 {
  border-top: none !important;
  border-bottom: none !important;
}

.u-bdr-top {
  border-top: 1px solid transparent !important;
}

.u-bdr-bottom {
  border-bottom: 1px solid transparent !important;
}

.u-bdr-left {
  border-left: 1px solid transparent !important;
}

.u-bdr-right {
  border-right: 1px solid transparent !important;
}

.u-bdr-dashed {
  border: 1px dashed transparent !important;
}
.u-bdr-dashed--y {
  border-top: 1px dashed transparent !important;
  border-bottom: 1px dashed transparent !important;
}
.u-bdr-dashed--x {
  border-right: 1px dashed transparent !important;
  border-left: 1px dashed transparent !important;
}
.u-bdr-dashed--top {
  border-top: 1px dashed #c0c0c8 !important;
}
.u-bdr-dashed--bottom {
  border-bottom: 1px dashed #c0c0c8 !important;
}
.u-bdr-dashed--left {
  border-left: 1px dashed transparent !important;
}
.u-bdr-dashed--right {
  border-right: 1px dashed transparent !important;
}

.u-bdr-dotted {
  border: 1px dotted transparent !important;
}
.u-bdr-dotted--y {
  border-top: 1px dotted transparent !important;
  border-bottom: 1px dotted transparent !important;
}
.u-bdr-dotted--x {
  border-right: 1px dotted transparent !important;
  border-left: 1px dotted transparent !important;
}
.u-bdr-dotted--top {
  border-top: 1px dashed dotted !important;
}
.u-bdr-dotted--bottom {
  border-bottom: 1px dotted transparent !important;
}
.u-bdr-dotted--left {
  border-left: 1px dotted transparent !important;
}
.u-bdr-dotted--right {
  border-right: 1px dotted transparent !important;
}

@media (min-width: 768px) {
  .u-bdr-md {
    border: 1px solid transparent !important;
  }
  .u-bdr-md-top {
    border-top: 1px solid transparent !important;
  }
  .u-bdr-md-bottom {
    border-bottom: 1px solid transparent !important;
  }
  .u-bdr-md-left {
    border-left: 1px solid transparent !important;
  }
  .u-bdr-md-right {
    border-right: 1px solid transparent !important;
  }
}

@media (min-width: 992px) {
  .u-bdr-lg {
    border: 1px solid transparent !important;
  }
  .u-bdr-lg-top {
    border-top: 1px solid transparent !important;
  }
  .u-bdr-lg-bottom {
    border-bottom: 1px solid transparent !important;
  }
  .u-bdr-lg-left {
    border-left: 1px solid transparent !important;
  }
  .u-bdr-lg-right {
    border-right: 1px solid transparent !important;
  }
}

.u-bdr-1 {
  border-width: 1px !important;
}

.u-bdr-2 {
  border-width: 2px !important;
}

.u-bdr-3 {
  border-width: 3px !important;
}

.u-bdr-4 {
  border-width: 4px !important;
}

.u-bdr-5 {
  border-width: 5px !important;
}

.u-rounded-0 {
  border-radius: 0px !important;
}

.u-rounded {
  border-radius: 10px;
}

.u-rounded-4 {
  border-radius: 4px !important;
}

.u-rounded-5 {
  border-radius: 5px !important;
}

.u-rounded-6 {
  border-radius: 6px !important;
}

.u-rounded-7 {
  border-radius: 7px !important;
}

.u-rounded-8 {
  border-radius: 8px !important;
}

.u-rounded-9 {
  border-radius: 9px !important;
}

.u-rounded-10 {
  border-radius: 10px !important;
}

.u-rounded-15 {
  border-radius: 15px !important;
}

.u-rounded-20 {
  border-radius: 20px !important;
}

.u-rounded-25 {
  border-radius: 25px !important;
}

.u-rounded-30 {
  border-radius: 30px !important;
}

.u-rounded-50 {
  border-radius: 1000em;
}

.u-rounded-left-50 {
  border-top-left-radius: 1000em;
  border-bottom-left-radius: 1000em;
}

.u-rounded-right-50 {
  border-top-right-radius: 1000em;
  border-bottom-right-radius: 1000em;
}

.u-bdrs-top-4 {
  border-radius: 4px 4px 0 0 !important;
}

.u-bdrs-top-6 {
  border-radius: 6px 6px 0 0 !important;
}

.u-bdrs-top-8 {
  border-radius: 8px 8px 0 0 !important;
}

.u-rounded-top {
  border-radius: 25px 25px 0 0 !important;
}

.u-rounded-bottom {
  border-radius: 0 0 25px 25px !important;
}

.u-rounded-bottom-left {
  border-radius: 0 0 0 25px !important;
}

.u-rounded-bottom-right {
  border-radius: 0 0 25px 0 !important;
}

.u-rounded-bottom-4 {
  border-radius: 0 0 4px 4px !important;
}

.u-rounded-bottom-6 {
  border-radius: 0 0 6px 6px !important;
}

.u-rounded-bottom-8 {
  border-radius: 0 0 8px 8px !important;
}

.u-rounded-right-4 {
  border-radius: 0 4px 4px 0 !important;
}

.u-rounded-right-6 {
  border-radius: 0 6px 6px 0 !important;
}

.u-rounded-right-8 {
  border-radius: 0 8px 8px 0 !important;
}

.u-rounded-left-4 {
  border-radius: 4px 0 0 4px !important;
}

.u-rounded-left-6 {
  border-radius: 6px 0 0 6px !important;
}

.u-rounded-left-8 {
  border-radius: 8px 0 0 8px !important;
}

.box-shadow-v1 {
  -webkit-box-shadow: 0 5px 50px 0 rgba(0, 81, 178, 0.1);
  box-shadow: 0 5px 50px 0 rgba(0, 81, 178, 0.1);
}
.box-shadow-v1--onHover {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.box-shadow-v1--onHover:hover {
  -webkit-box-shadow: 0 5px 50px 0 rgba(0, 81, 178, 0.1);
  box-shadow: 0 5px 50px 0 rgba(0, 81, 178, 0.1);
}

.box-shadow-v2 {
  -webkit-box-shadow: 0 0 50px 0 rgba(22, 104, 183, 0.15);
  box-shadow: 0 0 50px 0 rgba(22, 104, 183, 0.15);
}
.box-shadow-v2--onHover {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.box-shadow-v2--onHover:hover {
  -webkit-box-shadow: 0 0 50px 0 rgba(22, 104, 183, 0.15);
  box-shadow: 0 0 50px 0 rgba(22, 104, 183, 0.15);
}

.box-shadow-v3 {
  -webkit-box-shadow: 0 5px 50px 0 rgba(22, 104, 183, 0.05);
  box-shadow: 0 5px 50px 0 rgba(22, 104, 183, 0.05);
}
.box-shadow-v3--onHover {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.box-shadow-v3--onHover:hover {
  -webkit-box-shadow: 0 5px 50px 0 rgba(22, 104, 183, 0.05);
  box-shadow: 0 5px 50px 0 rgba(22, 104, 183, 0.05);
}

.box-shadow-v4 {
  -webkit-box-shadow: 0 5px 20px 0 rgba(24, 26, 26, 0.5);
  box-shadow: 0 5px 20px 0 rgba(24, 26, 26, 0.5);
}
.box-shadow-v4--onHover {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.box-shadow-v4--onHover:hover {
  -webkit-box-shadow: 0 5px 20px 0 rgba(24, 26, 26, 0.5);
  box-shadow: 0 5px 20px 0 rgba(24, 26, 26, 0.5);
}

.box-shadow-primary {
  -webkit-box-shadow: 0 5px 30px 0 rgba(0, 129, 255, 0.3);
  box-shadow: 0 5px 30px 0 rgba(0, 129, 255, 0.3);
}
.box-shadow-primary--onHover {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.box-shadow-primary--onHover:hover {
  -webkit-box-shadow: 0 5px 50px 0 rgba(22, 104, 183, 0.05);
  box-shadow: 0 5px 50px 0 rgba(22, 104, 183, 0.05);
}

.box-shadow-yellow {
  -webkit-box-shadow: 0 5px 30px 0 #fcc851;
  box-shadow: 0 5px 30px 0 #fcc851;
}
.box-shadow-primary--onHover {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.box-shadow-primary--onHover:hover {
  -webkit-box-shadow: 0 5px 50px 0 #fcc851;
  box-shadow: 0 5px 50px 0 rgba(22, 104, 183, 0.05);
}

.u-w-50vh {
  width: 50vh !important;
}

.u-w-100vh {
  width: 100vh !important;
}

.u-w-30p {
  width: 30% !important;
}

.u-w-40p {
  width: 40% !important;
}

.u-w-60p {
  width: 60% !important;
}

.u-w-70p {
  width: 70% !important;
}

.u-w-80p {
  width: 80% !important;
}

.u-w-90p {
  width: 90% !important;
}

.u-w-100p {
  width: 100% !important;
}

.u-flex-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.u-w-5 {
  width: 0.3125rem !important;
}

.u-w-10 {
  width: 0.625rem !important;
}

.u-w-15 {
  width: 0.9375rem !important;
}

.u-w-20 {
  width: 1.25rem !important;
}

.u-w-25 {
  width: 1.5625rem !important;
}

.u-w-30 {
  width: 1.875rem !important;
}

.u-w-35 {
  width: 2.1875rem !important;
}

.u-w-40 {
  width: 2.5rem !important;
}

.u-w-45 {
  width: 2.8125rem !important;
}

.u-w-50 {
  width: 3.125rem !important;
}

.u-w-55 {
  width: 3.4375rem !important;
}

.u-w-60 {
  width: 3.75rem !important;
}

.u-w-65 {
  width: 4.0625rem !important;
}

.u-w-70 {
  width: 4.375rem !important;
}

.u-w-75 {
  width: 4.6875rem !important;
}

.u-w-80 {
  width: 5rem !important;
}

.u-w-85 {
  width: 5.3125rem !important;
}

.u-w-90 {
  width: 5.625rem !important;
}

.u-w-95 {
  width: 5.9375rem !important;
}

.u-w-100 {
  width: 6.25rem !important;
}

.u-w-100 {
  width: 6.25rem !important;
}

.u-w-110 {
  width: 6.875rem !important;
}

.u-w-120 {
  width: 7.5rem !important;
}

.u-w-130 {
  width: 8.125rem !important;
}

.u-w-140 {
  width: 8.75rem !important;
}

.u-w-150 {
  width: 9.375rem !important;
}

.u-w-160 {
  width: 10rem !important;
}

.u-w-170 {
  width: 10.625rem !important;
}

.u-w-180 {
  width: 11.25rem !important;
}

.u-w-190 {
  width: 11.875rem !important;
}

.u-w-200 {
  width: 12.5rem !important;
}

.u-w-210 {
  width: 13.125rem !important;
}

.u-w-220 {
  width: 13.75rem !important;
}

.u-w-230 {
  width: 14.375rem !important;
}

.u-w-240 {
  width: 15rem !important;
}

.u-w-250 {
  width: 15.625rem !important;
}

.u-w-260 {
  width: 16.25rem !important;
}

.u-w-270 {
  width: 16.875rem !important;
}

.u-w-280 {
  width: 17.5rem !important;
}

.u-w-290 {
  width: 18.125rem !important;
}

.u-w-300 {
  width: 18.75rem !important;
}

.u-max-w-300 {
  width: 18.75rem !important;
}

.u-max-w-350 {
  width: 21.875rem !important;
}

.u-max-w-400 {
  width: 25rem !important;
}

.u-max-w-450 {
  width: 28.125rem !important;
}

.u-max-w-500 {
  width: 31.25rem !important;
}

.u-max-w-550 {
  width: 34.375rem !important;
}

.u-max-w-600 {
  width: 37.5rem !important;
}

.u-max-w-650 {
  width: 40.625rem !important;
}

.u-max-w-700 {
  width: 43.75rem !important;
}

.u-max-w-750 {
  width: 46.875rem !important;
}

.u-max-w-800 {
  width: 50rem !important;
}

.u-max-w-850 {
  width: 53.125rem !important;
}

.u-max-w-900 {
  width: 56.25rem !important;
}

.u-h-30vh {
  min-height: 30vh !important;
}

.u-h-40vh {
  min-height: 40vh !important;
}

.u-h-50vh {
  min-height: 50vh !important;
}

.u-h-60vh {
  min-height: 60vh !important;
}

.u-h-70vh {
  min-height: 70vh !important;
}

.u-h-80vh {
  min-height: 80vh !important;
}

.u-h-90vh {
  min-height: 90vh !important;
}

.u-h-100vh {
  min-height: 100vh !important;
}

.u-h-100p {
  height: 100% !important;
}

.u-h-auto {
  height: auto !important;
}

.u-h-2 {
  height: 0.125rem;
}

.u-h-4 {
  height: 0.25rem;
}

.u-h-6 {
  height: 0.375rem;
}

.u-h-8 {
  height: 0.5rem;
}

.u-h-10 {
  height: 0.625rem;
}

.u-h-12 {
  height: 0.75rem;
}

.u-h-14 {
  height: 0.875rem;
}

.u-h-16 {
  height: 1rem;
}

.u-h-18 {
  height: 1.125rem;
}

.u-h-20 {
  height: 1.25rem;
}

.u-h-5 {
  height: 0.3125rem;
}

.u-h-10 {
  height: 0.625rem;
}

.u-h-15 {
  height: 0.9375rem;
}

.u-h-20 {
  height: 1.25rem;
}

.u-h-25 {
  height: 1.5625rem;
}

.u-h-30 {
  height: 1.875rem;
}

.u-h-35 {
  height: 2.1875rem;
}

.u-h-40 {
  height: 2.5rem;
}

.u-h-45 {
  height: 2.8125rem;
}

.u-h-50 {
  height: 3.125rem;
}

.u-h-55 {
  height: 3.4375rem;
}

.u-h-60 {
  height: 3.75rem;
}

.u-h-65 {
  height: 4.0625rem;
}

.u-h-70 {
  height: 4.375rem;
}

.u-h-75 {
  height: 4.6875rem;
}

.u-h-80 {
  height: 5rem;
}

.u-h-85 {
  height: 5.3125rem;
}

.u-h-90 {
  height: 5.625rem;
}

.u-h-95 {
  height: 5.9375rem;
}

.u-h-100 {
  height: 6.25rem;
}

.u-h-100 {
  height: 6.25rem;
}

.u-h-110 {
  height: 6.875rem;
}

.u-h-120 {
  height: 7.5rem;
}

.u-h-130 {
  height: 8.125rem;
}

.u-h-140 {
  height: 8.75rem;
}

.u-h-150 {
  height: 9.375rem;
}

.u-h-160 {
  height: 10rem;
}

.u-h-170 {
  height: 10.625rem;
}

.u-h-180 {
  height: 11.25rem;
}

.u-h-190 {
  height: 11.875rem;
}

.u-h-200 {
  height: 12.5rem;
}

.u-h-210 {
  height: 13.125rem;
}

.u-h-220 {
  height: 13.75rem;
}

.u-h-230 {
  height: 14.375rem;
}

.u-h-240 {
  height: 15rem;
}

.u-h-250 {
  height: 15.625rem;
}

.u-h-260 {
  height: 16.25rem;
}

.u-h-270 {
  height: 16.875rem;
}

.u-h-280 {
  height: 17.5rem;
}

.u-h-290 {
  height: 18.125rem;
}

.u-h-300 {
  height: 18.75rem;
}

.u-fw-100 {
  font-weight: 100 !important;
}

.u-fw-200 {
  font-weight: 200 !important;
}

.u-fw-300 {
  font-weight: 300 !important;
}

.u-fw-400 {
  font-weight: 400 !important;
}

.u-fw-500 {
  font-weight: 500 !important;
}

.u-fw-600 {
  font-weight: 600 !important;
}

.u-fw-700 {
  font-weight: 700 !important;
}

.u-fw-800 {
  font-weight: 800 !important;
}

.u-fw-900 {
  font-weight: 900 !important;
}

@media (min-width: 768px) {
  fw-md-700 {
    font-weight: 700;
  }
  fw-md-800 {
    font-weight: 800;
  }
  fw-md-900 {
    font-weight: 900;
  }
}

@media (min-width: 992px) {
  fw-lg-700 {
    font-weight: 700;
  }
  fw-lg-800 {
    font-weight: 800;
  }
  fw-lg-900 {
    font-weight: 900;
  }
}

.u-p-5 {
  padding: 0.3125rem !important;
}

.u-p-6 {
  padding: 0.375rem !important;
}

.u-p-7 {
  padding: 0.4375rem !important;
}

.u-p-8 {
  padding: 0.5rem !important;
}

.u-p-9 {
  padding: 0.5625rem !important;
}

.u-p-10 {
  padding: 0.625rem !important;
}

.u-p-11 {
  padding: 0.6875rem !important;
}

.u-p-12 {
  padding: 0.75rem !important;
}

.u-p-13 {
  padding: 0.8125rem !important;
}

.u-p-14 {
  padding: 0.875rem !important;
}

.u-p-15 {
  padding: 0.9375rem !important;
}

.u-p-16 {
  padding: 1rem !important;
}

.u-p-17 {
  padding: 1.0625rem !important;
}

.u-p-18 {
  padding: 1.125rem !important;
}

.u-p-19 {
  padding: 1.1875rem !important;
}

.u-p-20 {
  padding: 1.25rem !important;
}

.u-p-20 {
  padding: 1.25rem !important;
}

.u-p-25 {
  padding: 1.5625rem !important;
}

.u-p-30 {
  padding: 1.875rem !important;
}

.u-p-35 {
  padding: 2.1875rem !important;
}

.u-p-40 {
  padding: 2.5rem !important;
}

.u-p-45 {
  padding: 2.8125rem !important;
}

.u-p-50 {
  padding: 3.125rem !important;
}

.u-p-55 {
  padding: 3.4375rem !important;
}

.u-p-60 {
  padding: 3.75rem !important;
}

.u-pt-5 {
  padding-top: 0.3125rem !important;
}

.u-pt-6 {
  padding-top: 0.375rem !important;
}

.u-pt-7 {
  padding-top: 0.4375rem !important;
}

.u-pt-8 {
  padding-top: 0.5rem !important;
}

.u-pt-9 {
  padding-top: 0.5625rem !important;
}

.u-pt-10 {
  padding-top: 0.625rem !important;
}

.u-pt-11 {
  padding-top: 0.6875rem !important;
}

.u-pt-12 {
  padding-top: 0.75rem !important;
}

.u-pt-13 {
  padding-top: 0.8125rem !important;
}

.u-pt-14 {
  padding-top: 0.875rem !important;
}

.u-pt-15 {
  padding-top: 0.9375rem !important;
}

.u-pt-16 {
  padding-top: 1rem !important;
}

.u-pt-17 {
  padding-top: 1.0625rem !important;
}

.u-pt-18 {
  padding-top: 1.125rem !important;
}

.u-pt-19 {
  padding-top: 1.1875rem !important;
}

.u-pt-20 {
  padding-top: 1.25rem !important;
}

.u-pt-20 {
  padding-top: 1.25rem !important;
}

.u-pt-25 {
  padding-top: 1.5625rem !important;
}

.u-pt-30 {
  padding-top: 1.875rem !important;
}

.u-pt-35 {
  padding-top: 2.1875rem !important;
}

.u-pt-40 {
  padding-top: 2.5rem !important;
}

.u-pt-45 {
  padding-top: 2.8125rem !important;
}

.u-pt-50 {
  padding-top: 3.125rem !important;
}

.u-pt-55 {
  padding-top: 3.4375rem !important;
}

.u-pt-60 {
  padding-top: 3.75rem !important;
}

.u-pt-65 {
  padding-top: 4.0625rem !important;
}

.u-pt-70 {
  padding-top: 4.375rem !important;
}

.u-pt-75 {
  padding-top: 4.6875rem !important;
}

.u-pt-80 {
  padding-top: 5rem !important;
}

.u-pt-85 {
  padding-top: 5.3125rem !important;
}

.u-pt-90 {
  padding-top: 5.625rem !important;
}

.u-pt-95 {
  padding-top: 5.9375rem !important;
}

.u-pt-100 {
  padding-top: 6.25rem !important;
}

.u-pb-5 {
  padding-bottom: 0.3125rem !important;
}

.u-pb-6 {
  padding-bottom: 0.375rem !important;
}

.u-pb-7 {
  padding-bottom: 0.4375rem !important;
}

.u-pb-8 {
  padding-bottom: 0.5rem !important;
}

.u-pb-9 {
  padding-bottom: 0.5625rem !important;
}

.u-pb-10 {
  padding-bottom: 0.625rem !important;
}

.u-pb-11 {
  padding-bottom: 0.6875rem !important;
}

.u-pb-12 {
  padding-bottom: 0.75rem !important;
}

.u-pb-13 {
  padding-bottom: 0.8125rem !important;
}

.u-pb-14 {
  padding-bottom: 0.875rem !important;
}

.u-pb-15 {
  padding-bottom: 0.9375rem !important;
}

.u-pb-16 {
  padding-bottom: 1rem !important;
}

.u-pb-17 {
  padding-bottom: 1.0625rem !important;
}

.u-pb-18 {
  padding-bottom: 1.125rem !important;
}

.u-pb-19 {
  padding-bottom: 1.1875rem !important;
}

.u-pb-20 {
  padding-bottom: 1.25rem !important;
}

.u-pb-25 {
  padding-bottom: 1.5625rem !important;
}

.u-pb-30 {
  padding-bottom: 1.875rem !important;
}

.u-pb-35 {
  padding-bottom: 2.1875rem !important;
}

.u-pb-40 {
  padding-bottom: 2.5rem !important;
}

.u-pb-45 {
  padding-bottom: 2.8125rem !important;
}

.u-pb-50 {
  padding-bottom: 3.125rem !important;
}

.u-pb-55 {
  padding-bottom: 3.4375rem !important;
}

.u-pb-60 {
  padding-bottom: 3.75rem !important;
}

.u-pb-65 {
  padding-bottom: 4.0625rem !important;
}

.u-pb-70 {
  padding-bottom: 4.375rem !important;
}

.u-pb-75 {
  padding-bottom: 4.6875rem !important;
}

.u-pb-80 {
  padding-bottom: 5rem !important;
}

.u-pb-85 {
  padding-bottom: 5.3125rem !important;
}

.u-pb-90 {
  padding-bottom: 5.625rem !important;
}

.u-pb-95 {
  padding-bottom: 5.9375rem !important;
}

.u-pb-100 {
  padding-bottom: 6.25rem !important;
}

.u-pl-5 {
  padding-left: 0.3125rem !important;
}

.u-pl-6 {
  padding-left: 0.375rem !important;
}

.u-pl-7 {
  padding-left: 0.4375rem !important;
}

.u-pl-8 {
  padding-left: 0.5rem !important;
}

.u-pl-9 {
  padding-left: 0.5625rem !important;
}

.u-pl-10 {
  padding-left: 0.625rem !important;
}

.u-pl-11 {
  padding-left: 0.6875rem !important;
}

.u-pl-12 {
  padding-left: 0.75rem !important;
}

.u-pl-13 {
  padding-left: 0.8125rem !important;
}

.u-pl-14 {
  padding-left: 0.875rem !important;
}

.u-pl-15 {
  padding-left: 0.9375rem !important;
}

.u-pl-16 {
  padding-left: 1rem !important;
}

.u-pl-17 {
  padding-left: 1.0625rem !important;
}

.u-pl-18 {
  padding-left: 1.125rem !important;
}

.u-pl-19 {
  padding-left: 1.1875rem !important;
}

.u-pl-20 {
  padding-left: 1.25rem !important;
}

.u-pl-20 {
  padding-left: 1.25rem !important;
}

.u-pl-25 {
  padding-left: 1.5625rem !important;
}

.u-pl-30 {
  padding-left: 1.875rem !important;
}

.u-pl-35 {
  padding-left: 2.1875rem !important;
}

.u-pl-40 {
  padding-left: 2.5rem !important;
}

.u-pl-45 {
  padding-left: 2.8125rem !important;
}

.u-pl-50 {
  padding-left: 3.125rem !important;
}

.u-pl-55 {
  padding-left: 3.4375rem !important;
}

.u-pl-60 {
  padding-left: 3.75rem !important;
}

.u-pl-65 {
  padding-left: 4.0625rem !important;
}

.u-pl-70 {
  padding-left: 4.375rem !important;
}

.u-pl-75 {
  padding-left: 4.6875rem !important;
}

.u-pl-80 {
  padding-left: 5rem !important;
}

.u-pl-85 {
  padding-left: 5.3125rem !important;
}

.u-pl-90 {
  padding-left: 5.625rem !important;
}

.u-pl-95 {
  padding-left: 5.9375rem !important;
}

.u-pl-100 {
  padding-left: 6.25rem !important;
}

.u-pr-5 {
  padding-right: 0.3125rem !important;
}

.u-pr-6 {
  padding-right: 0.375rem !important;
}

.u-pr-7 {
  padding-right: 0.4375rem !important;
}

.u-pr-8 {
  padding-right: 0.5rem !important;
}

.u-pr-9 {
  padding-right: 0.5625rem !important;
}

.u-pr-10 {
  padding-right: 0.625rem !important;
}

.u-pr-11 {
  padding-right: 0.6875rem !important;
}

.u-pr-12 {
  padding-right: 0.75rem !important;
}

.u-pr-13 {
  padding-right: 0.8125rem !important;
}

.u-pr-14 {
  padding-right: 0.875rem !important;
}

.u-pr-15 {
  padding-right: 0.9375rem !important;
}

.u-pr-16 {
  padding-right: 1rem !important;
}

.u-pr-17 {
  padding-right: 1.0625rem !important;
}

.u-pr-18 {
  padding-right: 1.125rem !important;
}

.u-pr-19 {
  padding-right: 1.1875rem !important;
}

.u-pr-20 {
  padding-right: 1.25rem !important;
}

.u-pr-20 {
  padding-right: 1.25rem !important;
}

.u-pr-25 {
  padding-right: 1.5625rem !important;
}

.u-pr-30 {
  padding-right: 1.875rem !important;
}

.u-pr-35 {
  padding-right: 2.1875rem !important;
}

.u-pr-40 {
  padding-right: 2.5rem !important;
}

.u-pr-45 {
  padding-right: 2.8125rem !important;
}

.u-pr-50 {
  padding-right: 3.125rem !important;
}

.u-pr-55 {
  padding-right: 3.4375rem !important;
}

.u-pr-60 {
  padding-right: 3.75rem !important;
}

.u-pr-65 {
  padding-right: 4.0625rem !important;
}

.u-pr-70 {
  padding-right: 4.375rem !important;
}

.u-pr-75 {
  padding-right: 4.6875rem !important;
}

.u-pr-80 {
  padding-right: 5rem !important;
}

.u-pr-85 {
  padding-right: 5.3125rem !important;
}

.u-pr-90 {
  padding-right: 5.625rem !important;
}

.u-pr-95 {
  padding-right: 5.9375rem !important;
}

.u-pr-100 {
  padding-right: 6.25rem !important;
}

.u-py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.u-py-5 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

.u-py-6 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.u-py-7 {
  padding-top: 0.4375rem !important;
  padding-bottom: 0.4375rem !important;
}

.u-py-8 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.u-py-9 {
  padding-top: 0.5625rem !important;
  padding-bottom: 0.5625rem !important;
}

.u-py-10 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.u-py-11 {
  padding-top: 0.6875rem !important;
  padding-bottom: 0.6875rem !important;
}

.u-py-12 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.u-py-13 {
  padding-top: 0.8125rem !important;
  padding-bottom: 0.8125rem !important;
}

.u-py-14 {
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
}

.u-py-15 {
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important;
}

.u-py-16 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.u-py-17 {
  padding-top: 1.0625rem !important;
  padding-bottom: 1.0625rem !important;
}

.u-py-18 {
  padding-top: 1.125rem !important;
  padding-bottom: 1.125rem !important;
}

.u-py-19 {
  padding-top: 1.1875rem !important;
  padding-bottom: 1.1875rem !important;
}

.u-py-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.u-py-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.u-py-25 {
  padding-top: 1.5625rem !important;
  padding-bottom: 1.5625rem !important;
}

.u-py-30 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.u-py-35 {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important;
}

.u-py-40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.u-py-45 {
  padding-top: 2.8125rem !important;
  padding-bottom: 2.8125rem !important;
}

.u-py-50 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.u-py-55 {
  padding-top: 3.4375rem !important;
  padding-bottom: 3.4375rem !important;
}

.u-py-60 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.u-py-65 {
  padding-top: 4.0625rem !important;
  padding-bottom: 4.0625rem !important;
}

.u-py-70 {
  padding-top: 4.375rem !important;
  padding-bottom: 4.375rem !important;
}

.u-py-75 {
  padding-top: 4.6875rem !important;
  padding-bottom: 4.6875rem !important;
}

.u-py-80 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.u-py-85 {
  padding-top: 5.3125rem !important;
  padding-bottom: 5.3125rem !important;
}

.u-py-90 {
  padding-top: 5.625rem !important;
  padding-bottom: 5.625rem !important;
}

.u-py-95 {
  padding-top: 5.9375rem !important;
  padding-bottom: 5.9375rem !important;
}

.u-py-100 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;
}

.u-px-5 {
  padding-left: 0.3125rem !important;
  padding-right: 0.3125rem !important;
}

.u-px-6 {
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
}

.u-px-7 {
  padding-left: 0.4375rem !important;
  padding-right: 0.4375rem !important;
}

.u-px-8 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.u-px-9 {
  padding-left: 0.5625rem !important;
  padding-right: 0.5625rem !important;
}

.u-px-10 {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}

.u-px-11 {
  padding-left: 0.6875rem !important;
  padding-right: 0.6875rem !important;
}

.u-px-12 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.u-px-13 {
  padding-left: 0.8125rem !important;
  padding-right: 0.8125rem !important;
}

.u-px-14 {
  padding-left: 0.875rem !important;
  padding-right: 0.875rem !important;
}

.u-px-15 {
  padding-left: 0.9375rem !important;
  padding-right: 0.9375rem !important;
}

.u-px-16 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.u-px-17 {
  padding-left: 1.0625rem !important;
  padding-right: 1.0625rem !important;
}

.u-px-18 {
  padding-left: 1.125rem !important;
  padding-right: 1.125rem !important;
}

.u-px-19 {
  padding-left: 1.1875rem !important;
  padding-right: 1.1875rem !important;
}

.u-px-20 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.u-px-20 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.u-px-25 {
  padding-left: 1.5625rem !important;
  padding-right: 1.5625rem !important;
}

.u-px-30 {
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important;
}

.u-px-35 {
  padding-left: 2.1875rem !important;
  padding-right: 2.1875rem !important;
}

.u-px-40 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.u-px-45 {
  padding-left: 2.8125rem !important;
  padding-right: 2.8125rem !important;
}

.u-px-50 {
  padding-left: 3.125rem !important;
  padding-right: 3.125rem !important;
}

.u-px-55 {
  padding-left: 3.4375rem !important;
  padding-right: 3.4375rem !important;
}

.u-px-60 {
  padding-left: 3.75rem !important;
  padding-right: 3.75rem !important;
}

.u-py-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.u-py-25 {
  padding-top: 1.5625rem !important;
  padding-bottom: 1.5625rem !important;
}

.u-py-30 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.u-py-35 {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important;
}

.u-py-40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.u-py-45 {
  padding-top: 2.8125rem !important;
  padding-bottom: 2.8125rem !important;
}

.u-py-50 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.u-py-55 {
  padding-top: 3.4375rem !important;
  padding-bottom: 3.4375rem !important;
}

.u-py-60 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.u-py-65 {
  padding-top: 4.0625rem !important;
  padding-bottom: 4.0625rem !important;
}

.u-py-70 {
  padding-top: 4.375rem !important;
  padding-bottom: 4.375rem !important;
}

.u-py-75 {
  padding-top: 4.6875rem !important;
  padding-bottom: 4.6875rem !important;
}

.u-py-80 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.u-py-85 {
  padding-top: 5.3125rem !important;
  padding-bottom: 5.3125rem !important;
}

.u-py-90 {
  padding-top: 5.625rem !important;
  padding-bottom: 5.625rem !important;
}

.u-py-95 {
  padding-top: 5.9375rem !important;
  padding-bottom: 5.9375rem !important;
}

.u-py-100 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;
}

@media (min-width: 768px) {
  .u-p-md-60 {
    padding: 3.75rem !important;
  }
  .u-p-md-65 {
    padding: 4.0625rem !important;
  }
  .u-p-md-70 {
    padding: 4.375rem !important;
  }
  .u-p-md-75 {
    padding: 4.6875rem !important;
  }
  .u-p-md-80 {
    padding: 5rem !important;
  }
  .u-p-md-85 {
    padding: 5.3125rem !important;
  }
  .u-p-md-90 {
    padding: 5.625rem !important;
  }
  .u-p-md-95 {
    padding: 5.9375rem !important;
  }
  .u-p-md-100 {
    padding: 6.25rem !important;
  }
  .u-p-md-105 {
    padding: 6.5625rem !important;
  }
  .u-p-md-110 {
    padding: 6.875rem !important;
  }
  .u-p-md-115 {
    padding: 7.1875rem !important;
  }
  .u-p-md-120 {
    padding: 7.5rem !important;
  }
  .u-p-md-125 {
    padding: 7.8125rem !important;
  }
  .u-p-md-130 {
    padding: 8.125rem !important;
  }
  .u-p-md-135 {
    padding: 8.4375rem !important;
  }
  .u-p-md-140 {
    padding: 8.75rem !important;
  }
  .u-p-md-145 {
    padding: 9.0625rem !important;
  }
  .u-p-md-150 {
    padding: 9.375rem !important;
  }
  .u-pt-md-60 {
    padding-top: 3.75rem !important;
  }
  .u-pt-md-65 {
    padding-top: 4.0625rem !important;
  }
  .u-pt-md-70 {
    padding-top: 4.375rem !important;
  }
  .u-pt-md-75 {
    padding-top: 4.6875rem !important;
  }
  .u-pt-md-80 {
    padding-top: 5rem !important;
  }
  .u-pt-md-85 {
    padding-top: 5.3125rem !important;
  }
  .u-pt-md-90 {
    padding-top: 5.625rem !important;
  }
  .u-pt-md-95 {
    padding-top: 5.9375rem !important;
  }
  .u-pt-md-100 {
    padding-top: 6.25rem !important;
  }
  .u-pt-md-105 {
    padding-top: 6.5625rem !important;
  }
  .u-pt-md-110 {
    padding-top: 6.875rem !important;
  }
  .u-pt-md-115 {
    padding-top: 7.1875rem !important;
  }
  .u-pt-md-120 {
    padding-top: 7.5rem !important;
  }
  .u-pt-md-125 {
    padding-top: 7.8125rem !important;
  }
  .u-pt-md-130 {
    padding-top: 8.125rem !important;
  }
  .u-pt-md-135 {
    padding-top: 8.4375rem !important;
  }
  .u-pt-md-140 {
    padding-top: 8.75rem !important;
  }
  .u-pt-md-145 {
    padding-top: 9.0625rem !important;
  }
  .u-pt-md-150 {
    padding-top: 9.375rem !important;
  }
  .u-pb-md-60 {
    padding-bottom: 3.75rem !important;
  }
  .u-pb-md-65 {
    padding-bottom: 4.0625rem !important;
  }
  .u-pb-md-70 {
    padding-bottom: 4.375rem !important;
  }
  .u-pb-md-75 {
    padding-bottom: 4.6875rem !important;
  }
  .u-pb-md-80 {
    padding-bottom: 5rem !important;
  }
  .u-pb-md-85 {
    padding-bottom: 5.3125rem !important;
  }
  .u-pb-md-90 {
    padding-bottom: 5.625rem !important;
  }
  .u-pb-md-95 {
    padding-bottom: 5.9375rem !important;
  }
  .u-pb-md-100 {
    padding-bottom: 6.25rem !important;
  }
  .u-pb-md-105 {
    padding-bottom: 6.5625rem !important;
  }
  .u-pb-md-110 {
    padding-bottom: 6.875rem !important;
  }
  .u-pb-md-115 {
    padding-bottom: 7.1875rem !important;
  }
  .u-pb-md-120 {
    padding-bottom: 7.5rem !important;
  }
  .u-pb-md-125 {
    padding-bottom: 7.8125rem !important;
  }
  .u-pb-md-130 {
    padding-bottom: 8.125rem !important;
  }
  .u-pb-md-135 {
    padding-bottom: 8.4375rem !important;
  }
  .u-pb-md-140 {
    padding-bottom: 8.75rem !important;
  }
  .u-pb-md-145 {
    padding-bottom: 9.0625rem !important;
  }
  .u-pb-md-150 {
    padding-bottom: 9.375rem !important;
  }
  .u-pl-md-60 {
    padding-left: 3.75rem !important;
  }
  .u-pl-md-65 {
    padding-left: 4.0625rem !important;
  }
  .u-pl-md-70 {
    padding-left: 4.375rem !important;
  }
  .u-pl-md-75 {
    padding-left: 4.6875rem !important;
  }
  .u-pl-md-80 {
    padding-left: 5rem !important;
  }
  .u-pl-md-85 {
    padding-left: 5.3125rem !important;
  }
  .u-pl-md-90 {
    padding-left: 5.625rem !important;
  }
  .u-pl-md-95 {
    padding-left: 5.9375rem !important;
  }
  .u-pl-md-100 {
    padding-left: 6.25rem !important;
  }
  .u-pl-md-105 {
    padding-left: 6.5625rem !important;
  }
  .u-pl-md-110 {
    padding-left: 6.875rem !important;
  }
  .u-pl-md-115 {
    padding-left: 7.1875rem !important;
  }
  .u-pl-md-120 {
    padding-left: 7.5rem !important;
  }
  .u-pl-md-125 {
    padding-left: 7.8125rem !important;
  }
  .u-pl-md-130 {
    padding-left: 8.125rem !important;
  }
  .u-pl-md-135 {
    padding-left: 8.4375rem !important;
  }
  .u-pl-md-140 {
    padding-left: 8.75rem !important;
  }
  .u-pl-md-145 {
    padding-left: 9.0625rem !important;
  }
  .u-pl-md-150 {
    padding-left: 9.375rem !important;
  }
  .u-pr-md-60 {
    padding-right: 3.75rem !important;
  }
  .u-pr-md-65 {
    padding-right: 4.0625rem !important;
  }
  .u-pr-md-70 {
    padding-right: 4.375rem !important;
  }
  .u-pr-md-75 {
    padding-right: 4.6875rem !important;
  }
  .u-pr-md-80 {
    padding-right: 5rem !important;
  }
  .u-pr-md-85 {
    padding-right: 5.3125rem !important;
  }
  .u-pr-md-90 {
    padding-right: 5.625rem !important;
  }
  .u-pr-md-95 {
    padding-right: 5.9375rem !important;
  }
  .u-pr-md-100 {
    padding-right: 6.25rem !important;
  }
  .u-pr-md-105 {
    padding-right: 6.5625rem !important;
  }
  .u-pr-md-110 {
    padding-right: 6.875rem !important;
  }
  .u-pr-md-115 {
    padding-right: 7.1875rem !important;
  }
  .u-pr-md-120 {
    padding-right: 7.5rem !important;
  }
  .u-pr-md-125 {
    padding-right: 7.8125rem !important;
  }
  .u-pr-md-130 {
    padding-right: 8.125rem !important;
  }
  .u-pr-md-135 {
    padding-right: 8.4375rem !important;
  }
  .u-pr-md-140 {
    padding-right: 8.75rem !important;
  }
  .u-pr-md-145 {
    padding-right: 9.0625rem !important;
  }
  .u-pr-md-150 {
    padding-right: 9.375rem !important;
  }
  .u-py-md-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .u-py-md-65 {
    padding-top: 4.0625rem !important;
    padding-bottom: 4.0625rem !important;
  }
  .u-py-md-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .u-py-md-75 {
    padding-top: 4.6875rem !important;
    padding-bottom: 4.6875rem !important;
  }
  .u-py-md-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .u-py-md-85 {
    padding-top: 5.3125rem !important;
    padding-bottom: 5.3125rem !important;
  }
  .u-py-md-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .u-py-md-95 {
    padding-top: 5.9375rem !important;
    padding-bottom: 5.9375rem !important;
  }
  .u-py-md-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .u-py-md-105 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important;
  }
  .u-py-md-110 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important;
  }
  .u-py-md-115 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important;
  }
  .u-py-md-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .u-py-md-125 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important;
  }
  .u-py-md-130 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important;
  }
  .u-py-md-135 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important;
  }
  .u-py-md-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .u-py-md-145 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important;
  }
  .u-py-md-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .u-px-md-60 {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important;
  }
  .u-px-md-65 {
    padding-left: 4.0625rem !important;
    padding-right: 4.0625rem !important;
  }
  .u-px-md-70 {
    padding-left: 4.375rem !important;
    padding-right: 4.375rem !important;
  }
  .u-px-md-75 {
    padding-left: 4.6875rem !important;
    padding-right: 4.6875rem !important;
  }
  .u-px-md-80 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .u-px-md-85 {
    padding-left: 5.3125rem !important;
    padding-right: 5.3125rem !important;
  }
  .u-px-md-90 {
    padding-left: 5.625rem !important;
    padding-right: 5.625rem !important;
  }
  .u-px-md-95 {
    padding-left: 5.9375rem !important;
    padding-right: 5.9375rem !important;
  }
  .u-px-md-100 {
    padding-left: 6.25rem !important;
    padding-right: 6.25rem !important;
  }
  .u-px-md-105 {
    padding-left: 6.5625rem !important;
    padding-right: 6.5625rem !important;
  }
  .u-px-md-110 {
    padding-left: 6.875rem !important;
    padding-right: 6.875rem !important;
  }
  .u-px-md-115 {
    padding-left: 7.1875rem !important;
    padding-right: 7.1875rem !important;
  }
  .u-px-md-120 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .u-px-md-125 {
    padding-left: 7.8125rem !important;
    padding-right: 7.8125rem !important;
  }
  .u-px-md-130 {
    padding-left: 8.125rem !important;
    padding-right: 8.125rem !important;
  }
  .u-px-md-135 {
    padding-left: 8.4375rem !important;
    padding-right: 8.4375rem !important;
  }
  .u-px-md-140 {
    padding-left: 8.75rem !important;
    padding-right: 8.75rem !important;
  }
  .u-px-md-145 {
    padding-left: 9.0625rem !important;
    padding-right: 9.0625rem !important;
  }
  .u-px-md-150 {
    padding-left: 9.375rem !important;
    padding-right: 9.375rem !important;
  }
}

@media (min-width: 992px) {
  .u-p-lg-100 {
    padding: 6.25rem !important;
  }
  .u-p-lg-110 {
    padding: 6.875rem !important;
  }
  .u-p-lg-120 {
    padding: 7.5rem !important;
  }
  .u-p-lg-130 {
    padding: 8.125rem !important;
  }
  .u-p-lg-140 {
    padding: 8.75rem !important;
  }
  .u-p-lg-150 {
    padding: 9.375rem !important;
  }
  .u-p-lg-160 {
    padding: 10rem !important;
  }
  .u-p-lg-170 {
    padding: 10.625rem !important;
  }
  .u-p-lg-180 {
    padding: 11.25rem !important;
  }
  .u-p-lg-190 {
    padding: 11.875rem !important;
  }
  .u-p-lg-200 {
    padding: 12.5rem !important;
  }
  .u-p-lg-210 {
    padding: 13.125rem !important;
  }
  .u-p-lg-220 {
    padding: 13.75rem !important;
  }
  .u-p-lg-230 {
    padding: 14.375rem !important;
  }
  .u-p-lg-240 {
    padding: 15rem !important;
  }
  .u-p-lg-250 {
    padding: 15.625rem !important;
  }
  .u-pt-lg-100 {
    padding-top: 6.25rem !important;
  }
  .u-pt-lg-110 {
    padding-top: 6.875rem !important;
  }
  .u-pt-lg-120 {
    padding-top: 7.5rem !important;
  }
  .u-pt-lg-130 {
    padding-top: 8.125rem !important;
  }
  .u-pt-lg-140 {
    padding-top: 8.75rem !important;
  }
  .u-pt-lg-150 {
    padding-top: 9.375rem !important;
  }
  .u-pt-lg-160 {
    padding-top: 10rem !important;
  }
  .u-pt-lg-170 {
    padding-top: 10.625rem !important;
  }
  .u-pt-lg-180 {
    padding-top: 11.25rem !important;
  }
  .u-pt-lg-190 {
    padding-top: 11.875rem !important;
  }
  .u-pt-lg-200 {
    padding-top: 12.5rem !important;
  }
  .u-pt-lg-210 {
    padding-top: 13.125rem !important;
  }
  .u-pt-lg-220 {
    padding-top: 13.75rem !important;
  }
  .u-pt-lg-230 {
    padding-top: 14.375rem !important;
  }
  .u-pt-lg-240 {
    padding-top: 15rem !important;
  }
  .u-pt-lg-250 {
    padding-top: 15.625rem !important;
  }
  .u-pb-lg-100 {
    padding-bottom: 6.25rem !important;
  }
  .u-pb-lg-110 {
    padding-bottom: 6.875rem !important;
  }
  .u-pb-lg-120 {
    padding-bottom: 7.5rem !important;
  }
  .u-pb-lg-130 {
    padding-bottom: 8.125rem !important;
  }
  .u-pb-lg-140 {
    padding-bottom: 8.75rem !important;
  }
  .u-pb-lg-150 {
    padding-bottom: 9.375rem !important;
  }
  .u-pb-lg-160 {
    padding-bottom: 10rem !important;
  }
  .u-pb-lg-170 {
    padding-bottom: 10.625rem !important;
  }
  .u-pb-lg-180 {
    padding-bottom: 11.25rem !important;
  }
  .u-pb-lg-190 {
    padding-bottom: 11.875rem !important;
  }
  .u-pb-lg-200 {
    padding-bottom: 12.5rem !important;
  }
  .u-pb-lg-210 {
    padding-bottom: 13.125rem !important;
  }
  .u-pb-lg-220 {
    padding-bottom: 13.75rem !important;
  }
  .u-pb-lg-230 {
    padding-bottom: 14.375rem !important;
  }
  .u-pb-lg-240 {
    padding-bottom: 15rem !important;
  }
  .u-pb-lg-250 {
    padding-bottom: 15.625rem !important;
  }
  .u-pl-lg-100 {
    padding-left: 6.25rem !important;
  }
  .u-pl-lg-110 {
    padding-left: 6.875rem !important;
  }
  .u-pl-lg-120 {
    padding-left: 7.5rem !important;
  }
  .u-pl-lg-130 {
    padding-left: 8.125rem !important;
  }
  .u-pl-lg-140 {
    padding-left: 8.75rem !important;
  }
  .u-pl-lg-150 {
    padding-left: 9.375rem !important;
  }
  .u-pl-lg-160 {
    padding-left: 10rem !important;
  }
  .u-pl-lg-170 {
    padding-left: 10.625rem !important;
  }
  .u-pl-lg-180 {
    padding-left: 11.25rem !important;
  }
  .u-pl-lg-190 {
    padding-left: 11.875rem !important;
  }
  .u-pl-lg-200 {
    padding-left: 12.5rem !important;
  }
  .u-pl-lg-210 {
    padding-left: 13.125rem !important;
  }
  .u-pl-lg-220 {
    padding-left: 13.75rem !important;
  }
  .u-pl-lg-230 {
    padding-left: 14.375rem !important;
  }
  .u-pl-lg-240 {
    padding-left: 15rem !important;
  }
  .u-pl-lg-250 {
    padding-left: 15.625rem !important;
  }
  .u-pr-lg-100 {
    padding-right: 6.25rem !important;
  }
  .u-pr-lg-110 {
    padding-right: 6.875rem !important;
  }
  .u-pr-lg-120 {
    padding-right: 7.5rem !important;
  }
  .u-pr-lg-130 {
    padding-right: 8.125rem !important;
  }
  .u-pr-lg-140 {
    padding-right: 8.75rem !important;
  }
  .u-pr-lg-150 {
    padding-right: 9.375rem !important;
  }
  .u-pr-lg-160 {
    padding-right: 10rem !important;
  }
  .u-pr-lg-170 {
    padding-right: 10.625rem !important;
  }
  .u-pr-lg-180 {
    padding-right: 11.25rem !important;
  }
  .u-pr-lg-190 {
    padding-right: 11.875rem !important;
  }
  .u-pr-lg-200 {
    padding-right: 12.5rem !important;
  }
  .u-pr-lg-210 {
    padding-right: 13.125rem !important;
  }
  .u-pr-lg-220 {
    padding-right: 13.75rem !important;
  }
  .u-pr-lg-230 {
    padding-right: 14.375rem !important;
  }
  .u-pr-lg-240 {
    padding-right: 15rem !important;
  }
  .u-pr-lg-250 {
    padding-right: 15.625rem !important;
  }
  .u-py-lg-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .u-py-lg-110 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important;
  }
  .u-py-lg-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .u-py-lg-130 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important;
  }
  .u-py-lg-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .u-py-lg-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .u-py-lg-160 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .u-py-lg-170 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important;
  }
  .u-py-lg-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .u-py-lg-190 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important;
  }
  .u-py-lg-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .u-py-lg-210 {
    padding-top: 13.125rem !important;
    padding-bottom: 13.125rem !important;
  }
  .u-py-lg-220 {
    padding-top: 13.75rem !important;
    padding-bottom: 13.75rem !important;
  }
  .u-py-lg-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .u-py-lg-240 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .u-py-lg-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .u-px-lg-100 {
    padding-left: 6.25rem !important;
    padding-right: 6.25rem !important;
  }
  .u-px-lg-110 {
    padding-left: 6.875rem !important;
    padding-right: 6.875rem !important;
  }
  .u-px-lg-120 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .u-px-lg-130 {
    padding-left: 8.125rem !important;
    padding-right: 8.125rem !important;
  }
  .u-px-lg-140 {
    padding-left: 8.75rem !important;
    padding-right: 8.75rem !important;
  }
  .u-px-lg-150 {
    padding-left: 9.375rem !important;
    padding-right: 9.375rem !important;
  }
  .u-px-lg-160 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .u-px-lg-170 {
    padding-left: 10.625rem !important;
    padding-right: 10.625rem !important;
  }
  .u-px-lg-180 {
    padding-left: 11.25rem !important;
    padding-right: 11.25rem !important;
  }
  .u-px-lg-190 {
    padding-left: 11.875rem !important;
    padding-right: 11.875rem !important;
  }
  .u-px-lg-200 {
    padding-left: 12.5rem !important;
    padding-right: 12.5rem !important;
  }
  .u-px-lg-210 {
    padding-left: 13.125rem !important;
    padding-right: 13.125rem !important;
  }
  .u-px-lg-220 {
    padding-left: 13.75rem !important;
    padding-right: 13.75rem !important;
  }
  .u-px-lg-230 {
    padding-left: 14.375rem !important;
    padding-right: 14.375rem !important;
  }
  .u-px-lg-240 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }
  .u-px-lg-250 {
    padding-left: 15.625rem !important;
    padding-right: 15.625rem !important;
  }
}

.u-lh-0 {
  line-height: 0;
}

.u-lh-0_7 {
  line-height: 0.7 !important;
}

.u-lh-0_8 {
  line-height: 0.8 !important;
}

.u-lh-0_9 {
  line-height: 0.9 !important;
}

.u-lh-1 {
  line-height: 1 !important;
}

.u-lh-1_1 {
  line-height: 1.1 !important;
}

.u-lh-1_2 {
  line-height: 1.2 !important;
}

.u-lh-1_3 {
  line-height: 1.3 !important;
}

.u-lh-1_4 {
  line-height: 1.4 !important;
}

.u-lh-1_5 {
  line-height: 1.5 !important;
}

.u-lh-1_57 {
  line-height: 1.57 !important;
}

.u-lh-1_6 {
  line-height: 1.6 !important;
}

.u-lh-1_7 {
  line-height: 1.7 !important;
}

.u-lh-1_8 {
  line-height: 1.8 !important;
}

.u-lh-2 {
  line-height: 2 !important;
}

.u-pos-abs {
  position: absolute;
}

.u-pos-rel {
  position: relative;
}

.u-pos-st {
  position: static;
}

@media (min-width: 768px) {
  .u-pos-md-abs {
    position: absolute;
  }
}

@media (min-width: 992px) {
  .u-pos-lg-abs {
    position: absolute;
  }
}

.u-pos-abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.u-pos-abs-center--x {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.u-pos-abs-center--y {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.u-flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.u-right-0 {
  right: 0 !important;
}

.u-right-5 {
  right: 0.3125rem !important;
}

.u-right-10 {
  right: 0.625rem !important;
}

.u-right-15 {
  right: 0.9375rem !important;
}

.u-right-20 {
  right: 1.25rem !important;
}

.u-right-25 {
  right: 1.5625rem !important;
}

.u-right-30 {
  right: 1.875rem !important;
}

.u-right-35 {
  right: 2.1875rem !important;
}

.u-right-40 {
  right: 2.5rem !important;
}

.u-right-45 {
  right: 2.8125rem !important;
}

.u-right-50 {
  right: 3.125rem !important;
}

.u-right-minus-5 {
  right: -0.3125rem !important;
}

.u-right-minus-10 {
  right: -0.625rem !important;
}

.u-right-minus-15 {
  right: -0.9375rem !important;
}

.u-right-minus-20 {
  right: -1.25rem !important;
}

.u-right-minus-25 {
  right: -1.5625rem !important;
}

.u-right-minus-30 {
  right: -1.875rem !important;
}

.u-right-minus-35 {
  right: -2.1875rem !important;
}

.u-right-minus-40 {
  right: -2.5rem !important;
}

.u-right-minus-45 {
  right: -2.8125rem !important;
}

.u-right-minus-50 {
  right: -3.125rem !important;
}

.u-right-25p {
  right: 25% !important;
}

.u-right-50p {
  right: 50% !important;
}

.u-right-75p {
  right: 75% !important;
}

.u-right-100p {
  right: 100% !important;
}

.u-left-0 {
  left: 0 !important;
}

.u-left-5 {
  left: 0.3125rem !important;
}

.u-left-10 {
  left: 0.625rem !important;
}

.u-left-15 {
  left: 0.9375rem !important;
}

.u-left-20 {
  left: 1.25rem !important;
}

.u-left-25 {
  left: 1.5625rem !important;
}

.u-left-30 {
  left: 1.875rem !important;
}

.u-left-35 {
  left: 2.1875rem !important;
}

.u-left-40 {
  left: 2.5rem !important;
}

.u-left-45 {
  left: 2.8125rem !important;
}

.u-left-50 {
  left: 3.125rem !important;
}

.u-left-minus-5 {
  left: -0.3125rem !important;
}

.u-left-minus-10 {
  left: -0.625rem !important;
}

.u-left-minus-15 {
  left: -0.9375rem !important;
}

.u-left-minus-20 {
  left: -1.25rem !important;
}

.u-left-minus-25 {
  left: -1.5625rem !important;
}

.u-left-minus-30 {
  left: -1.875rem !important;
}

.u-left-minus-35 {
  left: -2.1875rem !important;
}

.u-left-minus-40 {
  left: -2.5rem !important;
}

.u-left-minus-45 {
  left: -2.8125rem !important;
}

.u-left-minus-50 {
  left: -3.125rem !important;
}

.u-left-25p {
  left: 25% !important;
}

.u-left-50p {
  left: 50% !important;
}

.u-left-75p {
  left: 75% !important;
}

.u-left-100p {
  left: 100% !important;
}

.u-top-0 {
  top: 0 !important;
}

.u-top-5 {
  top: 0.3125rem !important;
}

.u-top-10 {
  top: 0.625rem !important;
}

.u-top-15 {
  top: 0.9375rem !important;
}

.u-top-20 {
  top: 1.25rem !important;
}

.u-top-25 {
  top: 1.5625rem !important;
}

.u-top-30 {
  top: 1.875rem !important;
}

.u-top-35 {
  top: 2.1875rem !important;
}

.u-top-40 {
  top: 2.5rem !important;
}

.u-top-45 {
  top: 2.8125rem !important;
}

.u-top-50 {
  top: 3.125rem !important;
}

.u-top-minus-5 {
  top: -0.3125rem !important;
}

.u-top-minus-10 {
  top: -0.625rem !important;
}

.u-top-minus-15 {
  top: -0.9375rem !important;
}

.u-top-minus-20 {
  top: -1.25rem !important;
}

.u-top-minus-25 {
  top: -1.5625rem !important;
}

.u-top-minus-30 {
  top: -1.875rem !important;
}

.u-top-minus-35 {
  top: -2.1875rem !important;
}

.u-top-minus-40 {
  top: -2.5rem !important;
}

.u-top-minus-45 {
  top: -2.8125rem !important;
}

.u-top-minus-50 {
  top: -3.125rem !important;
}

.u-top-25p {
  top: 25% !important;
}

.u-top-50p {
  top: 50% !important;
}

.u-top-75p {
  top: 75% !important;
}

.u-top-100p {
  top: 100% !important;
}

.u-bottom-0 {
  bottom: 0 !important;
}

.u-bottom-5 {
  bottom: 0.3125rem !important;
}

.u-bottom-10 {
  bottom: 0.625rem !important;
}

.u-bottom-15 {
  bottom: 0.9375rem !important;
}

.u-bottom-20 {
  bottom: 1.25rem !important;
}

.u-bottom-25 {
  bottom: 1.5625rem !important;
}

.u-bottom-30 {
  bottom: 1.875rem !important;
}

.u-bottom-35 {
  bottom: 2.1875rem !important;
}

.u-bottom-40 {
  bottom: 2.5rem !important;
}

.u-bottom-45 {
  bottom: 2.8125rem !important;
}

.u-bottom-50 {
  bottom: 3.125rem !important;
}

.u-bottom-minus-1 {
  bottom: -0.059rem !important;
}
@media (max-width: 768px) {
  .u-bottom-minus-1 {
    bottom: -0.1565rem !important;
  }
}

.u-bottom-minus-5 {
  bottom: -0.3125rem !important;
}

.u-bottom-minus-10 {
  bottom: -0.625rem !important;
}

.u-bottom-minus-15 {
  bottom: -0.9375rem !important;
}

.u-bottom-minus-20 {
  bottom: -1.25rem !important;
}

.u-bottom-minus-25 {
  bottom: -1.5625rem !important;
}

.u-bottom-minus-30 {
  bottom: -1.875rem !important;
}

.u-bottom-minus-35 {
  bottom: -2.1875rem !important;
}

.u-bottom-minus-40 {
  bottom: -2.5rem !important;
}

.u-bottom-minus-45 {
  bottom: -2.8125rem !important;
}

.u-bottom-minus-50 {
  bottom: -3.125rem !important;
}

.u-bottom-25p {
  bottom: 25% !important;
}

.u-bottom-50p {
  bottom: 50% !important;
}

.u-bottom-75p {
  bottom: 75% !important;
}

.u-bottom-100p {
  bottom: 100% !important;
}

.u-z-index-minus-1 {
  z-index: -1;
}

.u-z-index-1 {
  z-index: 1;
}

.u-z-index-5 {
  z-index: 5;
}

.u-z-index-10 {
  z-index: 10;
}

.u-z-index-20 {
  z-index: 20;
}

.u-z-index-50 {
  z-index: 50;
}

.bg-darkerWhite {
  background-color: #f5f5f5;
}

.bg-darkerWhite_2 {
  background-color: #efefef;
}

.bg-white {
  background: #fff !important;
}
.bg-white-opacity-0_1 {
  background: rgba(255, 255, 255, 0.1);
}
.bg-white-opacity-0_2 {
  background: rgba(255, 255, 255, 0.2);
}
.bg-white-opacity-0_3 {
  background: rgba(255, 255, 255, 0.3);
}
.bg-white-opacity-0_4 {
  background: rgba(255, 255, 255, 0.4);
}
.bg-white-opacity-0_5 {
  background: rgba(255, 255, 255, 0.5);
}
.bg-white-opacity-0_6 {
  background: rgba(255, 255, 255, 0.6);
}
.bg-white-opacity-0_7 {
  background: rgba(255, 255, 255, 0.7);
}
.bg-white-opacity-0_8 {
  background: rgba(255, 255, 255, 0.8);
}
.bg-white-opacity-0_9 {
  background: rgba(255, 255, 255, 0.9);
}

.bg-white--onHover:hover {
  background: #fff !important;
  color: #fff;
}

.bg-white-opacity {
  background: rgba(255, 255, 255, 0.75) !important;
}

.bg-black {
  background: #070b0e !important;
}
.bg-black-opacity-0_1 {
  background: rgba(17, 17, 17, 0.1);
}
.bg-black-opacity-0_2 {
  background: rgba(17, 17, 17, 0.2);
}
.bg-black-opacity-0_3 {
  background: rgba(17, 17, 17, 0.3);
}
.bg-black-opacity-0_4 {
  background: rgba(17, 17, 17, 0.4);
}
.bg-black-opacity-0_5 {
  background: rgba(17, 17, 17, 0.5);
}
.bg-black-opacity-0_6 {
  background: rgba(17, 17, 17, 0.6);
}
.bg-black-opacity-0_7 {
  background: rgba(17, 17, 17, 0.7);
}
.bg-black-opacity-0_8 {
  background: rgba(17, 17, 17, 0.8);
}
.bg-black-opacity-0_9 {
  background: rgba(17, 17, 17);
}

.bg-black--onHover:hover {
  background: #111 !important;
  color: #fff;
}

.bg-black-opacity {
  background: rgba(17, 17, 17, 0.75) !important;
}

.bg-blue {
  background: #3564ff !important;
}
.bg-blue-opacity-0_1 {
  background: rgba(0, 129, 255, 0.1);
}
.bg-blue-opacity-0_2 {
  background: rgba(0, 129, 255, 0.2);
}
.bg-blue-opacity-0_3 {
  background: rgba(0, 129, 255, 0.3);
}
.bg-blue-opacity-0_4 {
  background: rgba(0, 129, 255, 0.4);
}
.bg-blue-opacity-0_5 {
  background: rgba(0, 129, 255, 0.5);
}
.bg-blue-opacity-0_6 {
  background: rgba(0, 129, 255, 0.6);
}
.bg-blue-opacity-0_7 {
  background: rgba(0, 129, 255, 0.7);
}
.bg-blue-opacity-0_8 {
  background: rgba(0, 129, 255, 0.8);
}
.bg-blue-opacity-0_9 {
  background: rgba(0, 129, 255, 0.9);
}

.bg-blue--onHover:hover {
  background: #3564ff !important;
  color: #fff;
}

.bg-blue-opacity {
  background: rgba(0, 129, 255, 0.75) !important;
}

.bg-purple {
  background: #8c47e2 !important;
}
.bg-purple-opacity-0_1 {
  background: rgba(140, 71, 226, 0.1);
}
.bg-purple-opacity-0_2 {
  background: rgba(140, 71, 226, 0.2);
}
.bg-purple-opacity-0_3 {
  background: rgba(140, 71, 226, 0.3);
}
.bg-purple-opacity-0_4 {
  background: rgba(140, 71, 226, 0.4);
}
.bg-purple-opacity-0_5 {
  background: rgba(140, 71, 226, 0.5);
}
.bg-purple-opacity-0_6 {
  background: rgba(140, 71, 226, 0.6);
}
.bg-purple-opacity-0_7 {
  background: rgba(140, 71, 226, 0.7);
}
.bg-purple-opacity-0_8 {
  background: rgba(140, 71, 226, 0.8);
}
.bg-purple-opacity-0_9 {
  background: rgba(140, 71, 226, 0.9);
}

.bg-purple--onHover:hover {
  background: #8c47e2 !important;
  color: #fff;
}

.bg-purple-opacity {
  background: rgba(140, 71, 226, 0.75) !important;
}

.bg-purple-light {
  background: #a282e3 !important;
}
.bg-purple-light-opacity-0_1 {
  background: rgba(162, 130, 227, 0.1);
}
.bg-purple-light-opacity-0_2 {
  background: rgba(162, 130, 227, 0.2);
}
.bg-purple-light-opacity-0_3 {
  background: rgba(162, 130, 227, 0.3);
}
.bg-purple-light-opacity-0_4 {
  background: rgba(162, 130, 227, 0.4);
}
.bg-purple-light-opacity-0_5 {
  background: rgba(162, 130, 227, 0.5);
}
.bg-purple-light-opacity-0_6 {
  background: rgba(162, 130, 227, 0.6);
}
.bg-purple-light-opacity-0_7 {
  background: rgba(162, 130, 227, 0.7);
}
.bg-purple-light-opacity-0_8 {
  background: rgba(162, 130, 227, 0.8);
}
.bg-purple-light-opacity-0_9 {
  background: rgba(162, 130, 227, 0.9);
}

.bg-purple-light--onHover:hover {
  background: #a282e3 !important;
  color: #fff;
}

.bg-purple-light-opacity {
  background: rgba(162, 130, 227, 0.75) !important;
}

.bg-yellow {
  background: #fcc851 !important;
}
.bg-yellow-opacity-0_1 {
  background: rgba(255, 173, 0, 0.1);
}
.bg-yellow-opacity-0_2 {
  background: rgba(255, 173, 0, 0.2);
}
.bg-yellow-opacity-0_3 {
  background: rgba(255, 173, 0, 0.3);
}
.bg-yellow-opacity-0_4 {
  background: rgba(255, 173, 0, 0.4);
}
.bg-yellow-opacity-0_5 {
  background: rgba(255, 173, 0, 0.5);
}
.bg-yellow-opacity-0_6 {
  background: rgba(255, 173, 0, 0.6);
}
.bg-yellow-opacity-0_7 {
  background: rgba(255, 173, 0, 0.7);
}
.bg-yellow-opacity-0_8 {
  background: rgba(255, 173, 0, 0.8);
}
.bg-yellow-opacity-0_9 {
  background: rgba(255, 173, 0, 0.9);
}

.bg-yellow--onHover:hover {
  background: #ffad00 !important;
  color: #fff;
}

.bg-yellow-opacity {
  background: rgba(255, 173, 0, 0.75) !important;
}

.bg-light-grey {
  background: #f2f4f8 !important;
}

.bg-green {
  background: #2cd44a !important;
}
.bg-green-opacity-0_1 {
  background: rgba(44, 212, 74, 0.1);
}
.bg-green-opacity-0_2 {
  background: rgba(44, 212, 74, 0.2);
}
.bg-green-opacity-0_3 {
  background: rgba(44, 212, 74, 0.3);
}
.bg-green-opacity-0_4 {
  background: rgba(44, 212, 74, 0.4);
}
.bg-green-opacity-0_5 {
  background: rgba(44, 212, 74, 0.5);
}
.bg-green-opacity-0_6 {
  background: rgba(44, 212, 74, 0.6);
}
.bg-green-opacity-0_7 {
  background: rgba(44, 212, 74, 0.7);
}
.bg-green-opacity-0_8 {
  background: rgba(44, 212, 74, 0.8);
}
.bg-green-opacity-0_9 {
  background: rgba(44, 212, 74, 0.9);
}

.bg-green--onHover:hover {
  background: #2cd44a !important;
  color: #fff;
}

.bg-green-opacity {
  background: rgba(44, 212, 74, 0.75) !important;
}

.bg-red {
  background: #f64d62 !important;
}
.bg-red-opacity-0_1 {
  background: rgba(246, 77, 98, 0.1);
}
.bg-red-opacity-0_2 {
  background: rgba(246, 77, 98, 0.2);
}
.bg-red-opacity-0_3 {
  background: rgba(246, 77, 98, 0.3);
}
.bg-red-opacity-0_4 {
  background: rgba(246, 77, 98, 0.4);
}
.bg-red-opacity-0_5 {
  background: rgba(246, 77, 98, 0.5);
}
.bg-red-opacity-0_6 {
  background: rgba(246, 77, 98, 0.6);
}
.bg-red-opacity-0_7 {
  background: rgba(246, 77, 98, 0.7);
}
.bg-red-opacity-0_8 {
  background: rgba(246, 77, 98, 0.8);
}
.bg-red-opacity-0_9 {
  background: rgba(246, 77, 98, 0.9);
}

.bg-red--onHover:hover {
  background: #f64d62 !important;
  color: #fff;
}

.bg-red-opacity {
  background: rgba(246, 77, 98, 0.75) !important;
}

.bg-indigo {
  background: #3728f6 !important;
}
.bg-indigo-opacity-0_1 {
  background: rgba(93, 81, 246, 0.1);
}
.bg-indigo-opacity-0_2 {
  background: rgba(93, 81, 246, 0.2);
}
.bg-indigo-opacity-0_3 {
  background: rgba(93, 81, 246, 0.3);
}
.bg-indigo-opacity-0_4 {
  background: rgba(93, 81, 246, 0.4);
}
.bg-indigo-opacity-0_5 {
  background: rgba(93, 81, 246, 0.5);
}
.bg-indigo-opacity-0_6 {
  background: rgba(93, 81, 246, 0.6);
}
.bg-indigo-opacity-0_7 {
  background: rgba(93, 81, 246, 0.7);
}
.bg-indigo-opacity-0_8 {
  background: rgba(93, 81, 246, 0.8);
}
.bg-indigo-opacity-0_9 {
  background: rgba(93, 81, 246, 0.9);
}

.bg-indigo--onHover:hover {
  background: #5d51f6 !important;
  color: #fff;
}

.bg-indigo-opacity {
  background: #5d51f6 !important;
}

.bg-lime {
  background: #c8d046 !important;
}
.bg-lime-opacity-0_1 {
  background: rgba(200, 208, 70, 0.1);
}
.bg-lime-opacity-0_2 {
  background: rgba(200, 208, 70, 0.2);
}
.bg-lime-opacity-0_3 {
  background: rgba(200, 208, 70, 0.3);
}
.bg-lime-opacity-0_4 {
  background: rgba(200, 208, 70, 0.4);
}
.bg-lime-opacity-0_5 {
  background: rgba(200, 208, 70, 0.5);
}
.bg-lime-opacity-0_6 {
  background: rgba(200, 208, 70, 0.6);
}
.bg-lime-opacity-0_7 {
  background: rgba(200, 208, 70, 0.7);
}
.bg-lime-opacity-0_8 {
  background: rgba(200, 208, 70, 0.8);
}
.bg-lime-opacity-0_9 {
  background: rgba(200, 208, 70, 0.9);
}

.bg-lime--onHover:hover {
  background: #c8d046 !important;
  color: #fff;
}

.bg-lime-opacity {
  background: rgba(200, 208, 70, 0.75) !important;
}

.bg-primary-opacity-0_1 {
  background: rgba(0, 129, 255, 0.1);
}

.bg-primary-opacity-0_2 {
  background: rgba(0, 129, 255, 0.2);
}

.bg-primary-opacity-0_3 {
  background: rgba(0, 129, 255, 0.3);
}

.bg-primary-opacity-0_4 {
  background: rgba(0, 129, 255, 0.4);
}

.bg-primary-opacity-0_5 {
  background: rgba(0, 129, 255, 0.5);
}

.bg-primary-opacity-0_6 {
  background: rgba(0, 129, 255, 0.6);
}

.bg-primary-opacity-0_7 {
  background: rgba(0, 129, 255, 0.7);
}

.bg-primary-opacity-0_8 {
  background: rgba(0, 129, 255, 0.8);
}

.bg-primary-opacity-0_9 {
  background: rgba(0, 129, 255, 0.9);
}

.bg-facebook {
  background: #3b5998;
}

.bg-twitter {
  background: #46acf9;
}

.bg-google {
  background: #dd4b39;
}

.bg-linkedin {
  background: #007bb6;
}

.bg-youtube {
  background: #bb0000;
}

.bg-instagram {
  background: #517fa4;
}

.bg-pinterest {
  background: #cb2027;
}

.bg-flickr {
  background: #ff0084;
}

.bg-tumblr {
  background: #32506d;
}

.bg-forusquare {
  background: #0072b1;
}

.bg-dribbble {
  background: #ea4c89;
}

.bg-vine {
  background: #00bf8f;
}

.text-white {
  color: #fff !important;
}

.text-dark-black {
  color: #252525 !important;
}

.text-blue {
  color: #3564ff !important;
}

.text-purple {
  color: #8c47e2 !important;
}

.text-purple-light {
  color: #a282e3 !important;
}

.text-yellow {
  color: #fcc851 !important;
}

.text-green {
  color: #22891f !important;
}

.text-red {
  color: #f64d62 !important;
}

.text-indigo {
  color: #5d51f6 !important;
}

.text-lime {
  color: #c8d046 !important;
}

.color-heading {
  color: #273f5b;
}

.bg-primary {
  background: #3564ff !important;
}

.bg-dark {
  background: #20283b !important;
}

.color-primary {
  color: #3564ff !important;
}

.color-main {
  color: #fcc851 !important;
}

.text-muted {
  color: #7b8697 !important;
}

.bg-navy {
  background: #273f5b;
  color: #a5bacf;
}

.bg-navy-dark {
  background: #20344a;
  color: #a5bacf;
}

.text-navy {
  color: #273f5b;
}

.text-navy-dark {
  color: #20344a;
}

@media (min-width: 767px) {
  .bg-two-step:before {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    background: #2c364c;
    top: 0;
  }
}

.purple-gradian {
  background: #8b3ac6;
  background-image: -webkit-linear-gradient(left, #8b3ac6 0%, #4d3399 100%);
  background-image: -o-linear-gradient(left, #8b3ac6 0%, #4d3399 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#8b3ac6),
    to(#4d3399)
  );
  background-image: linear-gradient(to right, #8b3ac6 0%, #4d3399 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF8B3AC6', endColorstr='#FF4D3399', GradientType=1);
}

.bg-gray-v1 {
  background: #f3f6fb;
}

.bg-gray-v2 {
  background: #ecf5fe;
}

.bg-gray-v3 {
  background: #f0f3fa;
}

.u-fs-10 {
  font-size: 0.625rem !important;
}

.u-fs-11 {
  font-size: 0.6875rem !important;
}

.u-fs-12 {
  font-size: 0.75rem !important;
}

.u-fs-13 {
  font-size: 0.8125rem !important;
}

.u-fs-14 {
  font-size: 0.875rem !important;
}

.u-fs-15 {
  font-size: 0.9375rem !important;
}

.u-fs-16 {
  font-size: 1rem !important;
}

.u-fs-17 {
  font-size: 1.0625rem !important;
}

.u-fs-18 {
  font-size: 1.125rem !important;
}

.u-fs-19 {
  font-size: 1.1875rem !important;
}

.u-fs-20 {
  font-size: 1.25rem !important;
}

.u-fs-21 {
  font-size: 1.3125rem !important;
}

.u-fs-22 {
  font-size: 1.375rem !important;
}

.u-fs-23 {
  font-size: 1.4375rem !important;
}

.u-fs-24 {
  font-size: 1.5rem !important;
}

.u-fs-25 {
  font-size: 1.5625rem !important;
}

.u-fs-26 {
  font-size: 1.625rem !important;
}

.u-fs-27 {
  font-size: 1.6875rem !important;
}

.u-fs-28 {
  font-size: 1.75rem !important;
}

.u-fs-29 {
  font-size: 1.8125rem !important;
}

.u-fs-30 {
  font-size: 1.875rem !important;
}

.u-fs-30 {
  font-size: 1.875rem !important;
}

.u-fs-32 {
  font-size: 2rem !important;
}

.u-fs-34 {
  font-size: 2.125rem !important;
}

.u-fs-36 {
  font-size: 2.25rem !important;
}

.u-fs-38 {
  font-size: 2.375rem !important;
}

.u-fs-40 {
  font-size: 2.5rem !important;
}

.u-fs-42 {
  font-size: 2.625rem !important;
}

.u-fs-44 {
  font-size: 2.75rem !important;
}

.u-fs-46 {
  font-size: 2.875rem !important;
}

.u-fs-48 {
  font-size: 3rem !important;
}

.u-fs-50 {
  font-size: 3.125rem !important;
}

.u-fs-52 {
  font-size: 3.25rem !important;
}

.u-fs-54 {
  font-size: 3.375rem !important;
}

.u-fs-56 {
  font-size: 3.5rem !important;
}

.u-fs-58 {
  font-size: 3.625rem !important;
}

.u-fs-60 {
  font-size: 3.75rem !important;
}

@media (min-width: 768px) {
  .u-fs-md-150 {
    font-size: 9.375rem !important;
  }
  .u-fs-md-20 {
    font-size: 1.25rem !important;
  }
  .u-fs-md-22 {
    font-size: 1.375rem !important;
  }
  .u-fs-md-24 {
    font-size: 1.5rem !important;
  }
  .u-fs-md-26 {
    font-size: 1.625rem !important;
  }
  .u-fs-md-28 {
    font-size: 1.75rem !important;
  }
  .u-fs-md-30 {
    font-size: 1.875rem !important;
  }
  .u-fs-md-32 {
    font-size: 2rem !important;
  }
  .u-fs-md-34 {
    font-size: 2.125rem !important;
  }
  .u-fs-md-36 {
    font-size: 2.25rem !important;
  }
  .u-fs-md-38 {
    font-size: 2.375rem !important;
  }
  .u-fs-md-40 {
    font-size: 2.5rem !important;
  }
  .u-fs-md-42 {
    font-size: 2.625rem !important;
  }
  .u-fs-md-44 {
    font-size: 2.75rem !important;
  }
  .u-fs-md-46 {
    font-size: 2.875rem !important;
  }
  .u-fs-md-48 {
    font-size: 3rem !important;
  }
  .u-fs-md-50 {
    font-size: 3.125rem !important;
  }
  .u-fs-md-52 {
    font-size: 3.25rem !important;
  }
  .u-fs-md-54 {
    font-size: 3.375rem !important;
  }
  .u-fs-md-56 {
    font-size: 3.5rem !important;
  }
  .u-fs-md-58 {
    font-size: 3.625rem !important;
  }
  .u-fs-md-60 {
    font-size: 3.75rem !important;
  }
  .u-fs-md-62 {
    font-size: 3.875rem !important;
  }
  .u-fs-md-64 {
    font-size: 4rem !important;
  }
  .u-fs-md-66 {
    font-size: 4.125rem !important;
  }
  .u-fs-md-68 {
    font-size: 4.25rem !important;
  }
  .u-fs-md-70 {
    font-size: 4.375rem !important;
  }
  .u-fs-md-72 {
    font-size: 4.5rem !important;
  }
  .u-fs-md-74 {
    font-size: 4.625rem !important;
  }
  .u-fs-md-76 {
    font-size: 4.75rem !important;
  }
  .u-fs-md-78 {
    font-size: 4.875rem !important;
  }
  .u-fs-md-80 {
    font-size: 5rem !important;
  }
}

@media (min-width: 992px) {
  .u-fs-lg-20 {
    font-size: 1.25rem !important;
  }
  .u-fs-lg-22 {
    font-size: 1.375rem !important;
  }
  .u-fs-lg-24 {
    font-size: 1.5rem !important;
  }
  .u-fs-lg-26 {
    font-size: 1.625rem !important;
  }
  .u-fs-lg-28 {
    font-size: 1.75rem !important;
  }
  .u-fs-lg-30 {
    font-size: 1.875rem !important;
  }
  .u-fs-lg-32 {
    font-size: 2rem !important;
  }
  .u-fs-lg-34 {
    font-size: 2.125rem !important;
  }
  .u-fs-lg-36 {
    font-size: 2.25rem !important;
  }
  .u-fs-lg-38 {
    font-size: 2.375rem !important;
  }
  .u-fs-lg-40 {
    font-size: 2.5rem !important;
  }
  .u-fs-lg-42 {
    font-size: 2.625rem !important;
  }
  .u-fs-lg-44 {
    font-size: 2.75rem !important;
  }
  .u-fs-lg-46 {
    font-size: 2.875rem !important;
  }
  .u-fs-lg-48 {
    font-size: 3rem !important;
  }
  .u-fs-lg-50 {
    font-size: 3.125rem !important;
  }
  .u-fs-lg-52 {
    font-size: 3.25rem !important;
  }
  .u-fs-lg-54 {
    font-size: 3.375rem !important;
  }
  .u-fs-lg-56 {
    font-size: 3.5rem !important;
  }
  .u-fs-lg-58 {
    font-size: 3.625rem !important;
  }
  .u-fs-lg-60 {
    font-size: 3.75rem !important;
  }
  .u-fs-lg-62 {
    font-size: 3.875rem !important;
  }
  .u-fs-lg-64 {
    font-size: 4rem !important;
  }
  .u-fs-lg-66 {
    font-size: 4.125rem !important;
  }
  .u-fs-lg-68 {
    font-size: 4.25rem !important;
  }
  .u-fs-lg-70 {
    font-size: 4.375rem !important;
  }
  .u-fs-lg-72 {
    font-size: 4.5rem !important;
  }
  .u-fs-lg-74 {
    font-size: 4.625rem !important;
  }
  .u-fs-lg-76 {
    font-size: 4.75rem !important;
  }
  .u-fs-lg-78 {
    font-size: 4.875rem !important;
  }
  .u-fs-lg-80 {
    font-size: 5rem !important;
  }
}

.u-mt-5 {
  margin-top: 0.3125rem !important;
}

.u-mt-10 {
  margin-top: 0.625rem !important;
}

.u-mt-15 {
  margin-top: 0.9375rem !important;
}

.u-mt-20 {
  margin-top: 1.25rem !important;
}

.u-mt-25 {
  margin-top: 1.5625rem !important;
}

.u-mt-30 {
  margin-top: 1.875rem !important;
}

.u-mt-35 {
  margin-top: 2.1875rem !important;
}

.u-mt-40 {
  margin-top: 2.5rem !important;
}

.u-mt-45 {
  margin-top: 2.8125rem !important;
}

.u-mt-50 {
  margin-top: 3.125rem !important;
}

.u-mt-55 {
  margin-top: 3.4375rem !important;
}

.u-mt-60 {
  margin-top: 3.75rem !important;
}

.u-mt-65 {
  margin-top: 4.0625rem !important;
}

.u-mt-70 {
  margin-top: 4.375rem !important;
}

.u-mt-75 {
  margin-top: 4.6875rem !important;
}

.u-mt-80 {
  margin-top: 5rem !important;
}

.u-mb-5 {
  margin-bottom: 0.3125rem !important;
}

.u-mb-10 {
  margin-bottom: 0.625rem !important;
}

.u-mb-15 {
  margin-bottom: 0.9375rem !important;
}

.u-mb-20 {
  margin-bottom: 1.25rem !important;
}

.u-mb-25 {
  margin-bottom: 1.5625rem !important;
}

.u-mb-30 {
  margin-bottom: 1.875rem !important;
}

.u-mb-35 {
  margin-bottom: 2.1875rem !important;
}

.u-mb-40 {
  margin-bottom: 2.5rem !important;
}

.u-mb-45 {
  margin-bottom: 2.8125rem !important;
}

.u-mb-50 {
  margin-bottom: 3.125rem !important;
}

.u-mb-55 {
  margin-bottom: 3.4375rem !important;
}

.u-mb-60 {
  margin-bottom: 3.75rem !important;
}

.u-mb-65 {
  margin-bottom: 4.0625rem !important;
}

.u-mb-70 {
  margin-bottom: 4.375rem !important;
}

.u-mb-75 {
  margin-bottom: 4.6875rem !important;
}

.u-mb-80 {
  margin-bottom: 5rem !important;
}

.u-ml-5 {
  margin-left: 0.3125rem !important;
}

.u-ml-10 {
  margin-left: 0.625rem !important;
}

.u-ml-15 {
  margin-left: 0.9375rem !important;
}

.u-ml-20 {
  margin-left: 1.25rem !important;
}

.u-ml-25 {
  margin-left: 1.5625rem !important;
}

.u-ml-30 {
  margin-left: 1.875rem !important;
}

.u-ml-35 {
  margin-left: 2.1875rem !important;
}

.u-ml-40 {
  margin-left: 2.5rem !important;
}

.u-ml-45 {
  margin-left: 2.8125rem !important;
}

.u-ml-50 {
  margin-left: 3.125rem !important;
}

.u-ml-55 {
  margin-left: 3.4375rem !important;
}

.u-ml-60 {
  margin-left: 3.75rem !important;
}

.u-ml-65 {
  margin-left: 4.0625rem !important;
}

.u-ml-70 {
  margin-left: 4.375rem !important;
}

.u-ml-75 {
  margin-left: 4.6875rem !important;
}

.u-ml-80 {
  margin-left: 5rem !important;
}

.u-pr-5 {
  padding-right: 0.3125rem !important;
}

.u-pr-10 {
  padding-right: 0.625rem !important;
}

.u-pr-15 {
  padding-right: 0.9375rem !important;
}

.u-pr-20 {
  padding-right: 1.25rem !important;
}

.u-pr-25 {
  padding-right: 1.5625rem !important;
}

.u-pr-30 {
  padding-right: 1.875rem !important;
}

.u-pr-35 {
  padding-right: 2.1875rem !important;
}

.u-pr-40 {
  padding-right: 2.5rem !important;
}

.u-pr-45 {
  padding-right: 2.8125rem !important;
}

.u-pr-50 {
  padding-right: 3.125rem !important;
}

.u-pr-55 {
  padding-right: 3.4375rem !important;
}

.u-pr-60 {
  padding-right: 3.75rem !important;
}

.u-pr-65 {
  padding-right: 4.0625rem !important;
}

.u-pr-70 {
  padding-right: 4.375rem !important;
}

.u-pr-75 {
  padding-right: 4.6875rem !important;
}

.u-pr-80 {
  padding-right: 5rem !important;
}

.u-my-5 {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important;
}

.u-my-10 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.u-my-15 {
  margin-top: 0.9375rem !important;
  margin-bottom: 0.9375rem !important;
}

.u-my-20 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.u-my-25 {
  margin-top: 1.5625rem !important;
  margin-bottom: 1.5625rem !important;
}

.u-my-30 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.u-my-35 {
  margin-top: 2.1875rem !important;
  margin-bottom: 2.1875rem !important;
}

.u-my-40 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.u-my-45 {
  margin-top: 2.8125rem !important;
  margin-bottom: 2.8125rem !important;
}

.u-my-50 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important;
}

.u-my-55 {
  margin-top: 3.4375rem !important;
  margin-bottom: 3.4375rem !important;
}

.u-my-60 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.u-my-65 {
  margin-top: 4.0625rem !important;
  margin-bottom: 4.0625rem !important;
}

.u-my-70 {
  margin-top: 4.375rem !important;
  margin-bottom: 4.375rem !important;
}

.u-my-75 {
  margin-top: 4.6875rem !important;
  margin-bottom: 4.6875rem !important;
}

.u-my-80 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.u-my-100 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.u-mx-5 {
  margin-left: 0.3125rem !important;
  margin-right: 0.3125rem !important;
}

.u-mx-6 {
  margin-left: 0.375rem !important;
  margin-right: 0.375rem !important;
}

.u-mx-7 {
  margin-left: 0.4375rem !important;
  margin-right: 0.4375rem !important;
}

.u-mx-8 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.u-mx-9 {
  margin-left: 0.5625rem !important;
  margin-right: 0.5625rem !important;
}

.u-mx-10 {
  margin-left: 0.625rem !important;
  margin-right: 0.625rem !important;
}

.u-mx-11 {
  margin-left: 0.6875rem !important;
  margin-right: 0.6875rem !important;
}

.u-mx-12 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.u-mx-13 {
  margin-left: 0.8125rem !important;
  margin-right: 0.8125rem !important;
}

.u-mx-14 {
  margin-left: 0.875rem !important;
  margin-right: 0.875rem !important;
}

.u-mx-15 {
  margin-left: 0.9375rem !important;
  margin-right: 0.9375rem !important;
}

.u-mx-16 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.u-mx-17 {
  margin-left: 1.0625rem !important;
  margin-right: 1.0625rem !important;
}

.u-mx-18 {
  margin-left: 1.125rem !important;
  margin-right: 1.125rem !important;
}

.u-mx-19 {
  margin-left: 1.1875rem !important;
  margin-right: 1.1875rem !important;
}

.u-mx-20 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.u-mx-21 {
  margin-left: 1.3125rem !important;
  margin-right: 1.3125rem !important;
}

.u-mx-22 {
  margin-left: 1.375rem !important;
  margin-right: 1.375rem !important;
}

.u-mx-23 {
  margin-left: 1.4375rem !important;
  margin-right: 1.4375rem !important;
}

.u-mx-24 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.u-mx-25 {
  margin-left: 1.5625rem !important;
  margin-right: 1.5625rem !important;
}

.u-mx-26 {
  margin-left: 1.625rem !important;
  margin-right: 1.625rem !important;
}

.u-mx-27 {
  margin-left: 1.6875rem !important;
  margin-right: 1.6875rem !important;
}

.u-mx-28 {
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}

.u-mx-29 {
  margin-left: 1.8125rem !important;
  margin-right: 1.8125rem !important;
}

.u-mx-30 {
  margin-left: 1.875rem !important;
  margin-right: 1.875rem !important;
}

.u-mx-31 {
  margin-left: 1.9375rem !important;
  margin-right: 1.9375rem !important;
}

.u-mx-32 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.u-mx-33 {
  margin-left: 2.0625rem !important;
  margin-right: 2.0625rem !important;
}

.u-mx-34 {
  margin-left: 2.125rem !important;
  margin-right: 2.125rem !important;
}

.u-mx-35 {
  margin-left: 2.1875rem !important;
  margin-right: 2.1875rem !important;
}

.u-mx-36 {
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}

.u-mx-37 {
  margin-left: 2.3125rem !important;
  margin-right: 2.3125rem !important;
}

.u-mx-38 {
  margin-left: 2.375rem !important;
  margin-right: 2.375rem !important;
}

.u-mx-39 {
  margin-left: 2.4375rem !important;
  margin-right: 2.4375rem !important;
}

.u-mx-40 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.u-mx-41 {
  margin-left: 2.5625rem !important;
  margin-right: 2.5625rem !important;
}

.u-mx-42 {
  margin-left: 2.625rem !important;
  margin-right: 2.625rem !important;
}

.u-mx-43 {
  margin-left: 2.6875rem !important;
  margin-right: 2.6875rem !important;
}

.u-mx-44 {
  margin-left: 2.75rem !important;
  margin-right: 2.75rem !important;
}

.u-mx-45 {
  margin-left: 2.8125rem !important;
  margin-right: 2.8125rem !important;
}

.u-mx-46 {
  margin-left: 2.875rem !important;
  margin-right: 2.875rem !important;
}

.u-mx-47 {
  margin-left: 2.9375rem !important;
  margin-right: 2.9375rem !important;
}

.u-mx-48 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.u-mx-49 {
  margin-left: 3.0625rem !important;
  margin-right: 3.0625rem !important;
}

.u-mx-50 {
  margin-left: 3.125rem !important;
  margin-right: 3.125rem !important;
}

.u-mx-51 {
  margin-left: 3.1875rem !important;
  margin-right: 3.1875rem !important;
}

.u-mx-52 {
  margin-left: 3.25rem !important;
  margin-right: 3.25rem !important;
}

.u-mx-53 {
  margin-left: 3.3125rem !important;
  margin-right: 3.3125rem !important;
}

.u-mx-54 {
  margin-left: 3.375rem !important;
  margin-right: 3.375rem !important;
}

.u-mx-55 {
  margin-left: 3.4375rem !important;
  margin-right: 3.4375rem !important;
}

.u-mx-56 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.u-mx-57 {
  margin-left: 3.5625rem !important;
  margin-right: 3.5625rem !important;
}

.u-mx-58 {
  margin-left: 3.625rem !important;
  margin-right: 3.625rem !important;
}

.u-mx-59 {
  margin-left: 3.6875rem !important;
  margin-right: 3.6875rem !important;
}

.u-mx-60 {
  margin-left: 3.75rem !important;
  margin-right: 3.75rem !important;
}

.u-mx-61 {
  margin-left: 3.8125rem !important;
  margin-right: 3.8125rem !important;
}

.u-mx-62 {
  margin-left: 3.875rem !important;
  margin-right: 3.875rem !important;
}

.u-mx-63 {
  margin-left: 3.9375rem !important;
  margin-right: 3.9375rem !important;
}

.u-mx-64 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.u-mx-65 {
  margin-left: 4.0625rem !important;
  margin-right: 4.0625rem !important;
}

.u-mx-66 {
  margin-left: 4.125rem !important;
  margin-right: 4.125rem !important;
}

.u-mx-67 {
  margin-left: 4.1875rem !important;
  margin-right: 4.1875rem !important;
}

.u-mx-68 {
  margin-left: 4.25rem !important;
  margin-right: 4.25rem !important;
}

.u-mx-69 {
  margin-left: 4.3125rem !important;
  margin-right: 4.3125rem !important;
}

.u-mx-70 {
  margin-left: 4.375rem !important;
  margin-right: 4.375rem !important;
}

.u-mx-71 {
  margin-left: 4.4375rem !important;
  margin-right: 4.4375rem !important;
}

.u-mx-72 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

.u-mx-73 {
  margin-left: 4.5625rem !important;
  margin-right: 4.5625rem !important;
}

.u-mx-74 {
  margin-left: 4.625rem !important;
  margin-right: 4.625rem !important;
}

.u-mx-75 {
  margin-left: 4.6875rem !important;
  margin-right: 4.6875rem !important;
}

.u-mx-76 {
  margin-left: 4.75rem !important;
  margin-right: 4.75rem !important;
}

.u-mx-77 {
  margin-left: 4.8125rem !important;
  margin-right: 4.8125rem !important;
}

.u-mx-78 {
  margin-left: 4.875rem !important;
  margin-right: 4.875rem !important;
}

.u-mx-79 {
  margin-left: 4.9375rem !important;
  margin-right: 4.9375rem !important;
}

.u-mx-80 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

@media (min-width: 768px) {
  .u-mt-md-80 {
    margin-top: 5rem !important;
  }
  .u-mt-md-75 {
    margin-top: 4.6875rem !important;
  }
  .u-mt-md-70 {
    margin-top: 4.375rem !important;
  }
  .u-mt-md-65 {
    margin-top: 4.0625rem !important;
  }
  .u-mt-md-60 {
    margin-top: 3.75rem !important;
  }
  .u-mt-md-55 {
    margin-top: 3.4375rem !important;
  }
  .u-mt-md-50 {
    margin-top: 3.125rem !important;
  }
  .u-mt-md-45 {
    margin-top: 2.8125rem !important;
  }
  .u-mt-md-40 {
    margin-top: 2.5rem !important;
  }
  .u-mt-md-35 {
    margin-top: 2.1875rem !important;
  }
  .u-mt-md-30 {
    margin-top: 1.875rem !important;
  }
  .u-mt-md-25 {
    margin-top: 1.5625rem !important;
  }
  .u-mt-md-20 {
    margin-top: 1.25rem !important;
  }
  .u-mt-md-15 {
    margin-top: 0.9375rem !important;
  }
  .u-mt-md-10 {
    margin-top: 0.625rem !important;
  }
  .u-mb-md-70 {
    margin-bottom: 2.3125rem !important;
  }
  .u-mb-md-80 {
    margin-bottom: 5rem !important;
  }
  .u-mb-md-85 {
    margin-bottom: 5.3125rem !important;
  }
  .u-mb-md-90 {
    margin-bottom: 5.625rem !important;
  }
  .u-mb-md-95 {
    margin-bottom: 5.9375rem !important;
  }
  .u-mb-md-100 {
    margin-bottom: 6.25rem !important;
  }
  .u-ml-md-80 {
    margin-left: 5rem !important;
  }
  .u-ml-md-85 {
    margin-left: 5.3125rem !important;
  }
  .u-ml-md-90 {
    margin-left: 5.625rem !important;
  }
  .u-ml-md-95 {
    margin-left: 5.9375rem !important;
  }
  .u-ml-md-100 {
    margin-left: 6.25rem !important;
  }
  .u-mr-md-80 {
    margin-right: 5rem !important;
  }
  .u-mr-md-85 {
    margin-right: 5.3125rem !important;
  }
  .u-mr-md-90 {
    margin-right: 5.625rem !important;
  }
  .u-mr-md-95 {
    margin-right: 5.9375rem !important;
  }
  .u-mr-md-100 {
    margin-right: 6.25rem !important;
  }
  .u-my-md-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .u-my-md-85 {
    margin-top: 5.3125rem !important;
    margin-bottom: 5.3125rem !important;
  }
  .u-my-md-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .u-my-md-95 {
    margin-top: 5.9375rem !important;
    margin-bottom: 5.9375rem !important;
  }
  .u-my-md-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .u-mx-md-80 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .u-mx-md-81 {
    margin-left: 5.0625rem !important;
    margin-right: 5.0625rem !important;
  }
  .u-mx-md-82 {
    margin-left: 5.125rem !important;
    margin-right: 5.125rem !important;
  }
  .u-mx-md-83 {
    margin-left: 5.1875rem !important;
    margin-right: 5.1875rem !important;
  }
  .u-mx-md-84 {
    margin-left: 5.25rem !important;
    margin-right: 5.25rem !important;
  }
  .u-mx-md-85 {
    margin-left: 5.3125rem !important;
    margin-right: 5.3125rem !important;
  }
  .u-mx-md-86 {
    margin-left: 5.375rem !important;
    margin-right: 5.375rem !important;
  }
  .u-mx-md-87 {
    margin-left: 5.4375rem !important;
    margin-right: 5.4375rem !important;
  }
  .u-mx-md-88 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }
  .u-mx-md-89 {
    margin-left: 5.5625rem !important;
    margin-right: 5.5625rem !important;
  }
  .u-mx-md-90 {
    margin-left: 5.625rem !important;
    margin-right: 5.625rem !important;
  }
  .u-mx-md-91 {
    margin-left: 5.6875rem !important;
    margin-right: 5.6875rem !important;
  }
  .u-mx-md-92 {
    margin-left: 5.75rem !important;
    margin-right: 5.75rem !important;
  }
  .u-mx-md-93 {
    margin-left: 5.8125rem !important;
    margin-right: 5.8125rem !important;
  }
  .u-mx-md-94 {
    margin-left: 5.875rem !important;
    margin-right: 5.875rem !important;
  }
  .u-mx-md-95 {
    margin-left: 5.9375rem !important;
    margin-right: 5.9375rem !important;
  }
  .u-mx-md-96 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .u-mx-md-97 {
    margin-left: 6.0625rem !important;
    margin-right: 6.0625rem !important;
  }
  .u-mx-md-98 {
    margin-left: 6.125rem !important;
    margin-right: 6.125rem !important;
  }
  .u-mx-md-99 {
    margin-left: 6.1875rem !important;
    margin-right: 6.1875rem !important;
  }
  .u-mx-md-100 {
    margin-left: 6.25rem !important;
    margin-right: 6.25rem !important;
  }
}

.card {
  border: none !important;
}

.tab__slider > .tab-pane {
  display: block;
  height: 0;
  overflow: visible;
}

.tab__slider > .active {
  height: auto;
}

.form-control {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.input-group .form-control {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.lead {
  font-size: 22px;
}

.progress {
  background: #d6ebff;
}
.progress-bar {
  height: 8px;
  background: #3564ff !important;
}

.slick-arrow {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  text-align: center;
  background: #fff;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 24px;
  padding: 5px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.slick-arrow:hover {
  background: #3564ff;
  color: #fff;
}

.slick-prev {
  left: -70px;
}

.slick-next {
  right: -70px;
}

.slick-dots {
  padding: 0;
  list-style: none;
}
.slick-dots > li {
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
}
.slick-dots > li > span {
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: rgba(0, 129, 255, 0.5);
  display: block;
  border: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}
.slick-dots > li.slick-active > span {
  width: 22px;
  background: #3564ff;
}
.slick-dots.dots-white > li > span {
  background: rgba(255, 255, 255, 0.7);
}
.slick-dots.dots-white > li.slick-active span {
  background: #fff;
}
.slick-dots.dots-green > li > span {
  background: rgba(44, 212, 74, 0.7);
}
.slick-dots.dots-green > li.slick-active span {
  background: #2cd44a;
}

.navbar {
  padding: 10px 0;
}

.navbar-light .navbar-brand {
  color: #000;
  font-size: 27px;
  font-weight: 900;
}

.navbar-light .navbar-nav .nav-link {
  color: #3e4348 !important;
}
