.align-items-right {
  justify-content: flex-end;
  line-height: 0.1em;
  padding-top: 5px;
}

.align-text-left h1,
h2,
h4,
p {
  text-align: left !important;
}

.about_hero {
  background: url(../../images/aboutus_bg.webp);
  background-size: cover;
  background-position: center center;
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  /*  width: 60px;*/
  /*height: 60px;*/
  bottom: 30px;
  right: 35px;
  background-color: #4fce5d;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 10px;
  margin-left: 7px;
}

.whatsapp_badge {
  background-color: #22891f;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
    margin-left: 7px;
  }

  .whatsapp_float {
    /*width: 40px;*/
    /*height: 40px;*/
    bottom: 15px;
    right: 25px;
    font-size: 16px;
  }
}

.button__badge {
  background-color: #fa3e3e;
  border-radius: 15px;
  color: white;
  padding: 1px 11px;
  font-size: 12px;
  position: absolute;
  top: 15;
  right: 5;
}

table {
  table-layout: fixed;
  word-wrap: break-word;
  margin-top: auto;
  margin-bottom: auto;
}
.text-sm {
  font-size: 12px;
}
.price {
  font-size: 3em;
}

.pricing-table td.is-highlighted,
.pricing-table th.is-highlighted {
  background-color: #e9f6e8;
}

.mobileHide {
  display: inline;
}

/* Smartphone Portrait and Landscape */
@media only screen and (min-device-width: 280px) and (max-device-width: 961px) {
  .mobileHide {
    display: none;
  }
}

.mobileShow {
  display: none;
}

/* Smartphone Portrait and Landscape */
@media only screen and (min-device-width: 280px) and (max-device-width: 961px) {
  .mobileShow {
    display: inline;
  }
}
